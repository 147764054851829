import React, { useState, useEffect, useRef, useContext } from "react";
import Utils from "./../Utils";
import _ from "lodash";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import UserContext from "../contexts/UserContext";

const JoinAsCorporate = (props) => {

    const $ = window.$;
    const [validated, setValidated] = useState(false);
    const { getServerData, setServerData } = useContext(UserContext);
    const [error, setError] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        form.classList.add("was-validated");
        if (form.checkValidity() === false) {
            return false;
        } else {
            setValidated(true);
            setError(false);
            setShowMessage(false);
            let frmdata = new FormData(form);
            setServerData(`corporate/request`, frmdata, "post")
                .then((res) => {
                    setShowMessage(res.data.message)
                })
                .catch((err) => {
                    console.log("Checkinggg", err);
                });
        }
    };

    useEffect(() => {
        $(".modal").on("show.bs.modal", function (e) {
            $(".modal .modal-dialog").attr("class", "modal-dialog modal-full zoomIn animated");
        });
        $(".modal").on("hide.bs.modal", function (e) {
            $(".modal .modal-dialog").attr("class", "modal-dialog zoomOut modal-full animated");
        });
    }, []);

    return (
        <>
            <div className="modal" id="reqForCorporate" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-full" role="document">
                    <div className="modal-content">
                        <div className="overlay"></div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <img className="img-fluid" src="/assets/images/close-circle.png" alt="AD closed" />
                        </button>
                        <div className="modal-body p-4">
                            <div className="container">
                                <div className="loginWrapper signupWrapper">
                                    <h3>
                                        Request for Corporate User
                                    </h3>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                                        {showMessage !== false && (
                                            <div className="alert alert-info p-3">
                                                <strong>{showMessage}</strong>
                                            </div>
                                        )}
                                        {error !== false && (
                                            <div className="alert alert-danger p-3">
                                                <strong>{error}</strong>
                                            </div>
                                        )}

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" controlId="validationFirstName">
                                                <Form.Label>First name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="First name"
                                                    name="first_name"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter First Name
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId="validationLastName">
                                                <Form.Label>Last name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Last name"
                                                    name="last_name"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Last Name.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId="validationMobile">
                                                <Form.Label>Mobile Number</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="number"
                                                    placeholder="9XXXXXXXX9"
                                                    name="mobile"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Mobile Number.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" controlId="validationEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" name="email" placeholder="Enter email" required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid Emaill Address.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" >
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Address" name="address" />
                                                <Form.Control.Feedback type="valid">
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>State</Form.Label>
                                                <Form.Control type="text" placeholder="State" name="state" />
                                                <Form.Control.Feedback type="valid">
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" controlId="validationCity">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control type="text" placeholder="Enter City" name="city" />
                                                <Form.Control.Feedback type="valid">
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId="validationCountry">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Country" name="country" required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid Country Name.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" className="d-flex align-items-end justify-content-end mt-2">
                                                <button type="submit" className="trainersignup mt-4 text-right">
                                                    {" "}
                                                    Request
                                                    <div className="icon">
                                                        <svg height="24" width="24" viewBox="0 0 24 24">
                                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="currentColor"></path>
                                                        </svg>
                                                    </div>
                                                </button>
                                            </Form.Group>
                                        </Row>

                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default JoinAsCorporate;
