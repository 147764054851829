import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = (props) => {
  useEffect(window.scrollEffect, []);

  return (
    <>
      <Container className="h-100 p-0">
        <div className="help-wrapper">
          <div className="container">
            {/*<nav>
            <ol className="cd-breadcrumb">
               <li><a href="/">Home</a></li>
               <li className="current"><em>Privacy Policy</em></li>
            </ol>
    </nav>*/}
            <div className="AD-studio">
              <h1 className="headingtext slideInUp wow ">Privacy Policy</h1>
              <p className="slideInUp wow">
                At KS-Tverse, we believe that a user’s information must be protected. This is why we understand the importance of ensuring that you
                understand how we treat the information you provide us on our site.
              </p>
              <h4 className="slideInUp wow">About Our Privacy Policy</h4>
              <p className="slideInUp wow">
                KS-Tverse gathers data, processes and utilizes it purely for business purposes, and makes sure that it is kept confidential. This
                Policy is intended toward the following customers/users of our website:
              </p>

              <p className="slideInUp wow">Customers who contact us via our “Contact Us” form or other forms on the website.</p>

              <p className="slideInUp wow">Customers or visitors who access our website for availing other professional services.</p>

              <p className="slideInUp wow">
                Please ensure to take some time out to read our Privacy Policy thoroughly. This Policy is a notification of how the information that
                you provide - your contact details, or other information that you enter on our website when making use of our services is protected
                and programmed to give you an optimal user-friendly experience.
              </p>

              <p className="slideInUp wow">
                All our content is accessible to users who visit our website. However, there may be certain services or features that require the
                visitor to register. We rely on your consent with regard to your data. You may withdraw your consent at any point by contacting us.
                You will also have the right to review, rectify, and access your personal data. If you wish to correct your information, such as your
                name, email address, or any other preferences, you are permitted to ask us for a copy of your information.
              </p>

              <p className="slideInUp wow">
                On the collection of data, internal research is conducted to identify your interests, demographics, and behaviours to personalize, and
                enhance our services; upgrade our marketing strategies, and improve our communications with you by processing your registration and
                orders. For example, the information collected is used solely for the following purposes:
              </p>

              <p className="slideInUp wow">
                Troubleshoot problems; determine consumer interest in our services; help promote safe matching; notify you about products, services,
                offers, and updates; identify and protect us against fraud, errors, and other criminal activity; customize your experience. We may
                also compare and evaluate your business information for accuracy, errors, or omissions.
              </p>

              <p className="slideInUp wow">
                We make use of website navigation data in combination with the business information you provide to get aggregated information about
                how users navigate on the KS-Tverse website which helps us control and improve your experience when you visit us.
              </p>

              <p className="slideInUp wow">
                The IP address is tracked when you make use of our website. This is done to monitor areas in our website that you navigate through or
                where you sign-up for our services. The address is also recorded to improve our advertising and website layout and for statistical
                purposes. In particular, the IP address is used for the following objectives:
              </p>

              <p className="slideInUp wow">
                To calculate the total number of users visiting our website from specific regions or countries in the world.
              </p>

              <p className="slideInUp wow">
                To promote advertising that is suitable for specific geographic locations or IP addresses. IP addresses are usually linked to
                universities, major corporations, or Internet service providers. The cumulative data collected from these addresses are conveyed to
                advertisers.
              </p>

              <p className="slideInUp wow">
                The business information that you provide is used for benchmarking analysis and aggregate statistics. This information is kept
                confidential.
              </p>

              <p className="slideInUp wow">
                The information we request from you on our website is solely for managing our services and enhancing the user experience.
              </p>
              <h4 className="slideInUp wow">Information Protection</h4>
              <p className="slideInUp wow">
                KS-Tverse offers you a high level of security to safeguard your information against theft, loss and unauthorized use, access, and
                modification. We employ administrative, reasonable, logical, and managerial measures that ensure that:
              </p>
              <p className="slideInUp wow">
                All our agents, independent contractors, and employees accept the non-disclosure agreements that maintain explicit confidentiality
                protections. Any individual violating such security and/or privacy policies is liable for possible termination and criminal and/or
                civil prosecution.
              </p>
              <p className="slideInUp wow">
                Optimum data accuracy is maintained and any unauthorized access is avoided by implementing specific technologies that protect and
                ensure that your data is only available to the recipient you have granted access to.
              </p>
              <p className="slideInUp wow">
                We rely on your consent with regard to your data. You may withdraw your consent at any point by contacting us at
                marcom@knowledgesynonyms. You will also have the right to review, rectify, and access your personal data. If you wish to correct your
                information, such as your name, email address, or any other preferences, you are permitted to ask us for a copy of your information.
              </p>
              <p className="slideInUp wow">
                <strong>NOTE: </strong>
              </p>
              <p className="slideInUp wow">
                No communication on the Internet is guaranteed confidential and, in essence, you give out information at your own risk.
              </p>
              <p className="slideInUp wow">
                <strong>DISCLAIMER: </strong>
              </p>
              <p className="slideInUp wow">
                You are not to use our communication tools to deliver spam or send content that would infringe our TOS. We investigate viruses, spam,
                phishing attacks, prohibited content, and other illegal or malicious activity on our website but do not store such messages sent via
                these tools.
              </p>
              <p className="slideInUp wow">
                This Privacy Policy is subject to updates and modifications from time to time. These changes will be brought to your attention as it
                relates to you in terms of changes in the process flow or as it impacts your right to data protection.
              </p>
            </div>
          </div>
        </div>
      </Container >
    </>
  );
};

export default PrivacyPolicy;
