import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Container, Tab, Row, Col, Button } from "react-bootstrap";
import { ProductForm, ProductResources, ProductContents } from "../components/products";
import DataTableGrid from "../components/DataTableGrid";
import axios from "axios";
import Utils from "../Utils";
import UserContext from "../contexts/UserContext";
import moment from "moment";
import { type } from "@testing-library/user-event/dist/type";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ManageProducts = (props) => {
  const { apiHeaders, getServerData, setServerData } = useContext(UserContext);
  const [showmc, setShowmc] = useState({ show: false, row: null, type: null });

  const [list, setList] = useState({ loading: false, error: false, pageInfo: {}, data: [] });
  const [showForm, setShowForm] = useState({ id: false, mode: 0 }); // 0=do not show, 1=add, 2=edit
  const MySwal = withReactContent(Swal);

  const listColumns = ["id", "name", "type", "is_course", "is_bundle", "productids", "duration", "created_at", "is_visible"];

  const toggleVisible = (e, pid) => {
    let chk = e.currentTarget;
    setServerData('trainer/my-products-visibility', `id=${pid}&visible=${chk.checked}`)
      .then(fetchList);
  };

  const columns = listColumns.map((v) => ({
    name: v.toUpperCase(),
    selector: (row) => row[v],
    format: (row) => {
      if (v === "type") {
        return row.is_course ? "Course" : row.is_bundle ? "Bundle" : "Product";
      } else if (v === "duration") {
        let duration = row.is_course ? row.duration : 0;
        if (row.is_bundle) {
          duration = _.sum(_.map(_.filter(list.data, p => row.productids.split(',').includes(p.id + "")), p => p.is_course ? p.duration : 0));
        }
        return row.is_course === 1 || row.is_bundle === 1 ? <div>{duration} Hrs</div> : <> - </>;
      } else if (v === "created_at") {
        return moment(row[v]).format("DD MMM YYYY");
      } else {
        return row[v];
      }
    },
    sortable: true,
    omit: ["is_course", "is_bundle", "productids", "is_visible"].includes(v),
    width: v === "id" ? "100px" : v === "name" ? undefined : "120px",
  }));

  columns.push({
    name: "Action",
    cell: (row) => (
      <>
        <div className="custom-switch pl-0 mt-1">
          <input className="productcheckbox" type="checkbox" id="delficon" name="deletefavicon" value="1" onChange={(e) => toggleVisible(e, row.id)} defaultChecked={row.is_visible} />
        </div>
        <Button
          size="sm"
          variant="light"
          className="mr-1"
          onClick={() => setShowmc({ show: true, row: row, type: "resource" })}
          data-toggle="tooltip"
          title={row.is_bundle ? "Bundle will have resources of their products only" : "Product Resources"}
          disabled={row.is_bundle}
        >
          <i className="fas fa-suitcase" />
        </Button>
        {/* <Button size='sm' variant="light" className="mr-1" onClick={() => setShowmc({show: true, row: row, type: 'content'})} data-toggle="tooltip" title="Product Content"><i className="fa fa-book" /></Button>*/}
        <Button
          size="sm"
          variant="light"
          className="mr-1"
          onClick={() => setShowForm({ mode: 2, id: row.id })}
          data-toggle="tooltip"
          title="Edit Product"
        >
          <i className="fa fa-edit" />
        </Button>
        <Button size="sm" variant="light" className="mr-1" onClick={deleteRecord(row.id)} data-toggle="tooltip" title="Delete Product">
          <i className="fa fa-trash text-danger" />
        </Button>
      </>
    ),
    sortable: false,
    width: "180px",
  });

  const deleteRecord = (id) => (e) => {
    MySwal.fire({
      title: 'You are going to delete record?',
      text: "Are you sure to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0f79aa',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success',
          axios.delete(Utils.apiUrl(`trainer/my-products/${id}`), apiHeaders()).then((res) => {
            fetchList();
          })
        )
      }
    })
  };

  const fetchList = () => {
    setList({ ...list, loading: true });
    let params = `?limit=999999`;
    getServerData("trainer/my-products" + params, true).then((res) => {
      if (res.success) {
        setList({ ...list, loading: false, error: false, pageInfo: res.pageInfo, data: res.data.map((v) => _.pick(v, listColumns)) });
      } else {
        setList({ ...list, loading: false, error: res.message, pageInfo: {}, data: [] });
      }
    });
  };
  const $ = window.$;

  useEffect(() => {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, []);

  useEffect(window.scrollEffect, []);
  useEffect(fetchList, []);

  const getAllProducts = () => {
    return list.data.filter((p) => p.is_bundle === 0);
  };

  return (
    <>
      <Container fluid className="h-100 p-0">
        <div className="profile-wrapper">
          <div className="container my-product mysale">
            <Tab.Container id="left-tabs-example">
              <div className="card-header ui-sortable-handle">
                <h3 className="card-title">Manage Products </h3>
                <span className="btn float-right">
                  {showForm.mode === 0 && (
                    <button className="btn btn-primary-outline bg-primary text-light btn-sm mt-1" onClick={() => setShowForm({ mode: 1, id: false })}>
                      Add Product <i className="fas fa-plus"></i>
                    </button>
                  )}
                  {showForm.mode > 0 && (
                    <button className="btn btn-secondary btn-sm mt-1" onClick={() => setShowForm({ mode: 0, id: false })}>
                      <i className="fas fa-arrow-left"></i> Go Back to listing
                    </button>
                  )}
                </span>
              </div>
              {showForm.mode === 0 && (
                <Row>
                  <Col sm={12}>
                    <DataTableGrid
                      pagination={true}
                      columns={columns}
                      data={list.data}
                      expandableRows
                      expandableRowDisabled={(row) => parseInt(row.is_bundle) === 0}
                      expandableRowsComponent={({ data }) => {
                        const products = _.filter(list.data, (p) => data.productids.split(",").includes(p.id + ""));
                        return (
                          <div className="card">
                            <div className="card-body p-3 pl-5">
                              <h5>Products contained in this bundle:</h5>
                              {products.map((p) => (
                                <div className="pb-1">{p.name}</div>
                              ))}
                            </div>
                          </div>
                        );
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Tab.Container>

            {showForm.mode > 0 && (
              <ProductForm
                type="page"
                id={showForm.id}
                allProducts={getAllProducts()}
                onClose={() => setShowForm({ ...showForm, mode: 0 })}
                onSave={fetchList}
              />
            )}
            {showmc.show && showmc.type === "resource" && (
              <ProductResources type="modal" id={showmc.row.id} name={showmc.row.name} onClose={() => setShowmc({ ...showmc, show: false })} />
            )}
            {showmc.show && showmc.type === "content" && (
              <ProductContents type="modal" id={showmc.row.id} name={showmc.row.name} onClose={() => setShowmc({ ...showmc, show: false })} />
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default ManageProducts;
