import React, { useContext, useState, useEffect } from "react";
import { Container, Alert, Card } from "react-bootstrap";
import UserContext from "./../contexts/UserContext";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { BlockTemplate, Loader, NotFound } from "../components";
import UtilityBlocks from "../components/UtilityBlocks";
import Utils from "../Utils";
import Home from "./Home";
import { TeacherSubscribe } from "../components/teacher";

const TrainerPage = (props) => {
  let { slug } = useParams();
  const [pageslug, setPageslug] = useState(slug);
  const { getServerData, getUserData } = useContext(UserContext);
  const [data, setData] = useState({ loading: true, about: {}, page: {}, blocks: [] });

  const fetchpage = () => {
    if (Utils.hasSubdomain()) {
      if (_.isEmpty(slug)) {
        slug = "home";
        setPageslug(slug);
      }
      getServerData(`trainer/getPageDetails/${Utils.subdomain()}/${slug}`)
        .then((res) => {
          setData({ loading: false, about: res.about, page: res.page, blocks: res.blocks });
        })
        .catch((e) => {
          setData({ loading: false, page: false, blocks: [] });
        });
    }
  };

  useEffect(fetchpage, []);

  useEffect(window.scrollEffect, []);

  const renderBlock = (block) => {
    let content = JSON.parse(block.content);
    if (content.type === "template") {
      return <BlockTemplate {...content} />;
    } else {
      try {
        const Comp = UtilityBlocks.getComponent(parseInt(content.pbbid));
        return <Comp sitesetting={props.sitesetting} settings={content.settings} />;
      } catch (e) {
        return <></>;
      }
    }
  };

  const renderNotFound = () => {
    return (
      <>
        {pageslug === "home" && (
          <Alert variant="success" className="w-100">
            Seems like{" "}
            <b>
              {data.about.firstname} {data.about.lastname}
            </b>{" "}
            is yet to configure pages. Please visit after sometime.
          </Alert>
        )}

        {pageslug !== "home" && <NotFound />}

        <Card className="mt-5">
          <Card.Body>
            <p className="text-left ContactSubscribe mt-4">
              Subscribe to Our {_.get(props, "sitesetting.company_name", process.env.REACT_APP_CONTACT_NAME)} Newsletter
            </p>
            <TeacherSubscribe type="inLine" />
          </Card.Body>
        </Card>
      </>
    );
  };

  return (
    <>
      {!Utils.hasSubdomain() && <Home />}
      {Utils.hasSubdomain() && (
        <Container className="h-100 p-0">
          <div className="profile-wrapper">
            <div className="container">
              {data.loading && (
                <Alert variant="light" className="p-5 m-5">
                  <Loader /> Loading page details...
                </Alert>
              )}
              {!data.loading && (
                <>
                  {_.get(data, "page.id", 0) > 0 && data.blocks.map(renderBlock)}
                  {_.get(data, "page.id", 0) === 0 && renderNotFound()}
                </>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default TrainerPage;
