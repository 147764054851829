import AcademicBlock from "./AcademicBlock";
import ProductListBlock from "./ProductListBlock";
import EventBlock from "./EventBlock";
import _ from "lodash";
import ExperienceBlock from "./ExperienceBlock";
import AwardBlock from "./AwardBlock";
import BlogsBlock from "./BlogsBlock";
import SliderBlock from "./SliderBlock";
import TestimonialBlock from "./TestimonialBlock";
import SubscribeBlock from "./SubscribeBlock";
import YoutubeBlock from "./YoutubeBlock";
import AboutMeBlock from "./AboutMeBlock";
import CalendlyBlock from "./CalendlyBlock";

const prebuiltBlocks = [
  {
    id: 1,
    label: "Products: limited list of products",
    component: ProductListBlock,
    settings: [
      {
        name: "Product Type",
        key: "product_type",
        type: "multi",
        choices: [
          { text: "product", value: "product", checked: true },
          { text: "course", value: "course", checked: true },
          { text: "bundle", value: "bundle", checked: true },
        ],
      },
      {
        name: "design",
        type: "single",
        choices: [
          { id: 1, image: "course-1.png", checked: true },
          { id: 2, image: "course-2.png", checked: false },
          { id: 3, image: "course-3.png", checked: false },
        ],
      },
      { name: "limit", type: "input", as: "number", placeholder: "No. of records" },
    ],
  },
  {
    id: 2,
    label: "Event: Featured event",
    component: EventBlock,
    settings: [
      { name: "featured", type: "boolean", default: false },
      {
        name: "design",
        type: "single",
        choices: [
          { id: 1, image: "event-1.png", checked: true },
          { id: 2, image: "event-2.png" },
        ],
        default: 1,
      },
      { name: "limit", type: "input", as: "number", placeholder: "No. of records" },
    ],
  },
  {
    id: 6,
    label: "Blogs - list of blog items",
    component: BlogsBlock,
    settings: [{ name: "limit", type: "input", as: "number", placeholder: "No. of records" }],
  },
  { id: 7, label: "Slider - carosel of images", component: SliderBlock },
  {
    id: 8,
    label: "List of Testimonials",
    component: TestimonialBlock,
    settings: [{ name: "limit", type: "input", as: "number", placeholder: "No. of records" }],
  },
  { id: 3, label: "Academic Qualification", component: AcademicBlock },
  { id: 4, label: "Professional Experiences", component: ExperienceBlock },
  { id: 5, label: "Awards/Certifications", component: AwardBlock },
  { id: 9, label: "Subscribe Newletter Block", component: SubscribeBlock },
  {
    id: 10,
    label: "Youtube Channel Block",
    component: YoutubeBlock,
    settings: [
      { name: "channel-id", type: "input", as: "text", placeholder: "Enter the channel ID" },
      { name: "limit", type: "input", as: "number", placeholder: "No. of records" },
    ],
  },
  {
    id: 11,
    label: "About Me Block",
    component: AboutMeBlock,
    settings: [
      {
        name: "Features",
        key: "features",
        type: "multi",
        choices: [
          { text: "rating", value: "rating", checked: true },
          { text: "social", value: "social", checked: true },
          { text: "share", value: "share", checked: true },
        ],
      },
    ],
  },
  {
    id: 12,
    label: "Calendly Meeting Block",
    component: CalendlyBlock,
    settings: [
      { name: "meeting_ID", type: "input", as: "text", placeholder: "Enter the Meeting Link" },
    ],
  },
];

const getLabel = (bid) => {
  return _.find(prebuiltBlocks, { id: bid }).label;
};

const getComponent = (bid) => {
  return _.find(prebuiltBlocks, { id: bid }).component;
};

const getSettingKeys = (bid) => {
  return _.get(_.find(prebuiltBlocks, { id: bid }), "settings", []);
};

export default { ProductListBlock, prebuiltBlocks, getLabel, getComponent, getSettingKeys };
