import { useEffect, useContext, useState } from "react";
import { Form, Alert, Spinner, Row, Col, Button, Modal } from "react-bootstrap";
import UserContext from "../../contexts/UserContext";
import TextEditor from "../TextEditor";
import _ from "lodash";

const ContentForm = (props) => {
  const [mode, setMode] = useState("Add");

  const [productcont, setProductCont] = useState({});
  const [saving, setSaving] = useState(false);
  const [response, setResponse] = useState({ success: false, message: "" });
  const { getServerData, setServerData } = useContext(UserContext);

  const onContentChange = (fld) => (value) => {
    let c = { ...productcont };
    c[fld] = value;
    setProductCont(c);
  };

  useEffect(() => {
    if (_.get(props, "id", false)) {
      let params = `fname=id&fvalue=${props.id}`;
      getServerData("trainer/product-content?" + params)
        .then((data) => setProductCont(data[0]))
        .then(() => setMode("Update"))
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(window.scrollEffect, []);

  useEffect(() => {
    window.setTimeout(() => setResponse({ message: "" }), 5000);
  }, [response]);

  const onSave = (e) => {
    const frm = e.currentTarget;
    e.preventDefault();
    let frmdata = new FormData(frm);
    frmdata.append("description", _.get(productcont, "description", ""));
    frmdata.append("embed_resource", _.get(productcont, "embed_resource", ""));
    setSaving(true);
    setServerData("trainer/product-content ", frmdata).then((res) => {
      setSaving(false);
      setResponse(res);
      props.onSave();
      props.onClose();
    });
  };
  const photoUploader = (fld, title) => {
    return (
      <>
        <Form.Label>{title}</Form.Label>
        <Form.Control type="file" size="lg" name={fld} accept=".mp4,.fly,.MP4;" />
      </>
    );
  };
  const renderForm = () => (
    <Form onSubmit={onSave}>
      <Form.Control type="hidden" name="product_id" defaultValue={_.get(productcont, "id", "")} />
      <Form.Control type="hidden" name="old_video" defaultValue={_.get(productcont, "video", "")} />
      <Form.Control type="hidden" name="product_id" value={props.product_id} />

      <h1>Product Create</h1>
      <Row>
        <Col md={12} className="mt-3">
          <Form.Label>Product Title: </Form.Label>
          <Form.Control type="text" name="title" placeholder="Enter product Title" defaultValue={_.get(productcont, "title", "")} />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mt-3">
          <Form.Label>Description: </Form.Label>
          <TextEditor value={_.get(productcont, "description", "")} onChange={onContentChange("description")} />
        </Col>
      </Row>
      <Row>
        <Col md={3} className="mt-3">
          {photoUploader("video", "Upload product lecture video")}
        </Col>
        <Col md={1} className="mt-3">
          <strong>OR</strong>
        </Col>
        <Col md={8} className="mt-3">
          <Form.Label>Embeded video: </Form.Label>
          <TextEditor value={_.get(productcont, "embed_resource", "")} onChange={onContentChange("embed_resource")} />
        </Col>
      </Row>

      <Row>
        <Col md={6} className="mt-3">
          <Form.Label>Duration: </Form.Label>
          <Form.Control type="text" name="duration" placeholder="Enter product duration" defaultValue={_.get(productcont, "duration", "")} />
        </Col>
        <Col md={6} className="mt-3">
          <Form.Label>lecture</Form.Label>
          <Form.Control type="text" name="lecture" placeholder="Enter no. of lecture in product" defaultValue={_.get(productcont, "lecture", "")} />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mt-3 text-right">
          {saving && (
            <>
              Saving.. <Spinner animation="border" />
            </>
          )}
          {!saving && response.message === "" && (
            <Button type="submit" className="profile-save">
              Save
            </Button>
          )}
          {!saving && response.message !== "" && (
            <Alert variant={response.success ? "info" : "danger"} className="p-3 mt-2 text-center">
              {response.message}
            </Alert>
          )}
        </Col>
      </Row>
    </Form>
  );

  const renderModal = () => (
    <Modal show={true} size="lg" onHide={_.get(props, "onClose", "")}>
      <Modal.Header closeButton>
        <Modal.Title>{mode} Product</Modal.Title>
      </Modal.Header>

      <Modal.Body>{renderForm()}</Modal.Body>
    </Modal>
  );

  return (
    <>
      {props.type !== "modal" && renderForm()}
      {props.type === "modal" && renderModal()}
    </>
  );
};

export default ContentForm;
