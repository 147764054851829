import React from "react";
import { Puff } from "react-loader-spinner";
import _ from "lodash";
const Loader = (props) => {
  return (
    <>
      <div className="profile-wrapper">
        <div className="container">
          <div className="m-5">
            <Puff
              height="100"
              width="100"
              radius={1}
              color="#0f79aa"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass={_.get(props, "className", "SiteLoader")}
              visible={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Loader;
