import { useEffect, useContext, useState } from "react";

import _ from "lodash";

import UserContext from "./../contexts/UserContext";

import Utils from "./../Utils";

//import TeacherLanding from './TeacherLanding';
import LandingPage from "./LandingPage";

const Home = (props) => {
  const hasSubdomain = Utils.hasSubdomain();
  const { getUserData, isLoggedIn, getServerData, addToUserData } = useContext(UserContext);

  const [pa, setPA] = useState([]);
  const calibs = _.get(getUserData(), "calibs", []);

  const [loggedIn, setLoggedIn] = useState(isLoggedIn());

  const [search, setSearch] = useState({});

  let tout = null;
  const setSearchText = (val, pa_id) => {
    if (tout) {
      window.clearTimeout(tout);
      tout = null;
    }
    window.setTimeout(() => {
      let txt = { ...search };
      txt[pa_id] = val;
      console.log("check", txt);
      setSearch({ ...txt });
    }, 500);
  };

  const getChildrenItems = (pa_id, children) => {
    if (_.get(search, pa_id, '') !== '') {
      return children.filter(c => c.title.toLowerCase().indexOf(search[pa_id].toLowerCase()) > -1);
    } else {
      return children;
    }
  }

  useEffect(() => {
    if (!hasSubdomain) {
      getServerData("profile_attributes")
        .then(setPA)
        .catch((err) => console.log(err));
    }
  }, []);

  const searchTrainers = (e) => {
    const frm = e.currentTarget;
    e.preventDefault();

    var data = {};
    _.each(frm.elements, (ele) => {
      if (ele.name.indexOf("pa_") > -1) {
        data[ele.name.substring(3)] = ele.value;
      }
    });

    addToUserData({ calibs: data });
    window.location.href = "/search-results";
  };

  const resetSearchTrainers = (e) => {
    const frm = e.currentTarget;
    e.preventDefault();
    addToUserData({ calibs: [] });
    window.location.reload();
  };

  const options = [
    "Industries",
    "Sub Industries",
    "Functional",
    "Sub Functional",
    "Qualifications",
    "University",
    "Experience",
    "Language",
    "Country",
    "Company",
    "Certifications",
  ];
  const [dFilters, setdFilters] = useState({
    18: { id: 18, parent_id: 1, children: [] },
    44: { id: 44, parent_id: 25, children: [] },
  });

  /*useEffect(() => {
    if (!hasSubdomain) {
      window.drumEffect({ onChange: onDrumChange });
    }
  }, [pa, dFilters]);*/

  const onDrumChange = (e) => {
    let vFilter = e.value;
    let parentId = parseInt(e.getAttribute("myid"));
    let dfIdx = _.get(_.find(dFilters, { parent_id: parentId }), "id", 0);
    let tmp = { ...dFilters };
    let chld = null;
    if (vFilter === "") {
      window.$(`#drum_pa_${dfIdx}`).remove();
      _.set(tmp, dfIdx, { id: dfIdx, parent_id: parentId, children: [] });
    } else {
      let allChildren = _.flatten(_.map(pa, (a) => a.children));
      vFilter = _.find(allChildren, { id: parseInt(vFilter) });
      if (parseInt(_.get(vFilter, "parent_id", 0)) > 0) {
        chld = _.get(vFilter, "children", []);
        if (chld.length === 0) {
          window.$(`#drum_pa_${dfIdx}`).remove();
        }
        _.set(tmp, dfIdx, { id: dfIdx, parent_id: vFilter.parent_id, children: chld });
      } else {
        window.$(`#drum_pa_${dfIdx}`).remove();
        _.set(tmp, dfIdx, { id: dfIdx, parent_id: parentId, children: [] });
      }
    }

    window.setTimeout((e) => {
      //window.$(`#container_${dfIdx}`).html();
      setdFilters(tmp);
    }, 500);
  };

  let queueCalling = [];

  useEffect(() => {
    if (!hasSubdomain) {
      _.each(dFilters, (d, idx) => {
        if (parseInt(_.get(calibs, d.parent_id, 0)) > 0) {
          queueCalling.push([parseInt(_.get(calibs, d.parent_id, 0)), d.parent_id]);
        }
      });
      callQueue();
    }
  }, [pa]);

  const callQueue = () => {
    console.log(new Date());
    if (queueCalling.length > 0) {
      let p = queueCalling[0];
      populateSubFilter(p[0], p[1]);
      queueCalling.shift();
      window.setTimeout(callQueue, 500); //=== queue calling magic
    }
  };

  //useEffect(callQueue, [dFilters]);

  const onFilterChange = (e) => {
    let vFilter = parseInt(e.target.value);
    let parentId = parseInt(e.target.getAttribute("myid"));
    populateSubFilter(vFilter, parentId);
  };

  const populateSubFilter = (vFilter, parentId) => {
    console.log("calling: ", vFilter, " ParentId: ", parentId);
    let dfIdx = _.get(_.find(dFilters, { parent_id: parentId }), "id", "");
    let tmp = { ...dFilters };
    let chld = null;
    if (vFilter === "") {
      _.set(tmp, dfIdx, { id: dfIdx, parent_id: parentId, children: [] });
    } else {
      let allChildren = _.flatten(_.map(pa, (a) => a.children));
      vFilter = _.find(allChildren, { id: parseInt(vFilter) });
      if (parseInt(_.get(vFilter, "parent_id", 0)) > 0) {
        chld = _.get(vFilter, "children", []);
        _.set(tmp, dfIdx, { id: dfIdx, parent_id: vFilter.parent_id, children: chld });
      } else {
        _.set(tmp, dfIdx, { id: dfIdx, parent_id: parentId, children: [] });
      }
    }

    setdFilters(tmp);
  };

  const renderPAFilter = (p) => {
    let dfilter = _.get(dFilters, p.id, {});
    let children = _.get(p, "children", []);
    if (_.get(dfilter, "children.length", 0) > 0) {
      children = dfilter.children;
    }


    return (
      <div className="col-md-4 col-lg-3 pt-1 pb-2 mb-4">
        <div className="hmesrch p-2" key={p.title}>
          <div className="alert bg-light text-dark filterbgcolor w-100">
            <b>{p.title}</b>
          </div>
          <div id={`container_${p.id}`}>
            <div class="search">
              <input class="search-input" type="text" placeholder="Search" onKeyUp={e => setSearchText(e.target.value, `${p.id}`)} />
            </div>
            {children.length === 0 && (
              <div className="text-center">
                {_.get(dfilter, "children.length", 0) === 0 && <>Please select {_.find(pa, { id: dfilter.parent_id }).title}</>}
              </div>
            )}

            {children.length > 0 && (
              <select className="selectClass" name={`pa_${p.id}`} myid={p.id} size={5} onChange={onFilterChange}>
                {getChildrenItems(`${p.id}`, children).map((pc) => (
                  <option className="p-1" key={pc.id} value={pc.id} selected={_.get(calibs, p.id, "") == pc.id}>
                    {pc.title}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {hasSubdomain && <LandingPage {...props} />}
      {!hasSubdomain && (
        <>
          <section className="home-wrapper">
            <div className="container">
              <div className="bannerText">WORLD'S TOP BRANDED CORPORATE TRAINERS TO TRANSFORM YOUR PROFESSIONAL CAREER</div>
              <div className="container bannerBottomtext clearfix">
                {!loggedIn && (
                  <ul>
                    <li className="jointrainer">
                      <a href="#signUpTrainer" data-toggle="modal" data-dismiss="modal">
                        Join as a Trainer
                      </a>
                    </li>
                    <li className="jointrainer ml-2">
                      <a href="#signUpStudent" data-toggle="modal" data-dismiss="modal">
                        Join as a Student
                      </a>
                    </li>
                    <li className="jointrainer ml-2">
                      <a href="#reqForCorporate" data-toggle="modal" data-dismiss="modal">
                        Request for Corporate Profile
                      </a>
                    </li>
                  </ul>
                )}
                <div className="whyAD">
                  <img className="img-fluid" src="/assets/images/why_ad.png" alt="Autodidact" />
                </div>
              </div>
            </div>
          </section>
          <div className="findBox">
            <div className="container">
              <h2>
                Find the <span className="findboxmid">ONE</span> for you!
              </h2>

              {_.get(pa, "length", 0) === 0 && (
                <div className="progress-bar bg-warning text-dark progress-bar-striped progress-bar-animated">Loading Profile Attributes</div>
              )}
              {_.get(pa, "length", 0) > 0 && (
                <div>
                  <form onSubmit={searchTrainers} onReset={resetSearchTrainers}>
                    <div className="row">{pa.map(renderPAFilter)}</div>

                    <div className="text-right">
                      <button type="reset" className="search-trainer me-2 mr-2 my-5">
                        <span className="transition"></span>
                        <span className="gradient"></span>
                        <span className="label">Clear</span>
                      </button>

                      <button type="submit" className="search-trainer my-5">
                        <span className="transition"></span>
                        <span className="gradient"></span>
                        <span className="label">Search</span>
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
