import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Modal, Carousel } from "react-bootstrap";
import Utils from "./../../Utils";
import UserContext from "./../../contexts/UserContext";
import _ from "lodash";
import moment from "moment";
import { Loader } from "./../../components";

const EventBlock = (props) => {
  const settings = _.get(props, "settings", {});
  const { getServerData, getUserData } = useContext(UserContext);
  const slug = Utils.hasSubdomain() ? Utils.subdomain() : getUserData().slug;
  const [tData, setTData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchEvents = () => {
    getServerData(`trainer/blocks/${slug}?mode[]=events&featured=${_.get(settings, "featured", false)}&limit=${_.get(settings, "limit", 3)}`, true)
      .then((tData) => {
        console.log(tData);
        setTData(tData);
        setLoading(false);
      })
      .catch((msg) => {
        setTData({ success: false, message: msg });
        setLoading(false);
      });
  };

  useEffect(fetchEvents, []);

  const renderEventBox = (event) => {
    return (
      <>
        <div className="col-lg-5 col-md-12">
          <div className="UpEventImg">
            <img className="img-fluid w-100" src={`${process.env.REACT_APP_API_URL}/uploads/event/${event.event_img}`} alt="" />
          </div>
        </div>
        <div className="col-lg-7 col-md-12">
          <div className="UpEventText">
            <h3>{event.heading}</h3>
            <h4>{event.sub_heading}</h4>
            <div>{moment(event.event_on).format("MMM DD, h:mm a")}</div>
            <p dangerouslySetInnerHTML={{ __html: event.event_short_desc }}></p>
            <div className="HomeRegister mt-4">
              {/*<button onClick={RegisterShow}>Register Now</button>*/}
              <button>
                {" "}
                <a href={event.cta} target="_blank">
                  Register Now
                </a>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderDesign2EventBox = () => {
    return (
      <>
        <div className="testimonialIndicator">
          <Carousel showIndicators controls={false} fade>
            {tData.events.map((event, idx) => (
              <Carousel.Item className="px-3" key={idx}>
                <Row>
                  <div className="col-lg-7 col-md-12">
                    <div className="UpEventText text-right">
                      <h3>{event.heading}</h3>
                      <h4>{event.sub_heading}</h4>
                      <div>{moment(event.event_on).format("MMM DD, h:mm a")}</div>
                      <p dangerouslySetInnerHTML={{ __html: event.event_short_desc }}></p>
                      <div className="HomeRegister mt-4">
                        <button>
                          {" "}
                          <a href={event.cta} target="_blank">
                            Register Now
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12">
                    <div className="UpEventImg">
                      <img className="img-fluid w-100" src={`${process.env.REACT_APP_API_URL}/uploads/event/${event.event_img}`} alt="" />
                    </div>
                  </div>
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </>
    );
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="mt-4 mb-4 landingUpEvent">
          <div className="landingUpEventBox">
            {parseInt(_.get(settings, "design", 2)) === 1 && (
              <Row>
                {tData.events.map((event) => (
                  <Col key={event.id} sm={12} className="pt-3 d-flex">
                    {renderEventBox(event)}
                  </Col>
                ))}
              </Row>
            )}
            {parseInt(_.get(settings, "design", 2)) === 2 && (
              <Row>
                <Col sm={12} className="pt-3 d-flex">
                  {renderDesign2EventBox()}
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EventBlock;
