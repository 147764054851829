import { useEffect, useContext, useState } from "react";
import { Form, Alert, Spinner, Row, Col, Button, Modal } from "react-bootstrap";
import UserContext from "./../../contexts/UserContext";
import TextEditor from "../TextEditor";
import _ from "lodash";
import Utils from "./../../Utils";

const TestimonialForm = (props) => {
  const [mode, setMode] = useState("Add");

  const [mytestimonial, setMyTestimonial] = useState({});
  const [saving, setSaving] = useState(false);
  const [response, setResponse] = useState({ success: false, message: "" });
  const { getServerData, setServerData } = useContext(UserContext);

  const onContentChange = (fld) => (value) => {
    let c = { ...mytestimonial };
    c[fld] = value;
    setMyTestimonial(c);
  };

  const setupEditor = (editor) => {
    editor.on("submit", () => {
      const length = editor.getContent({ format: "text" }).length;
      if (length === 0) {
        editor.notificationManager.open({
          text: "Short description is mandatory!",
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    if (_.get(props, "id", false)) {
      getServerData("trainer/testimonials?where[id]=" + props.id)
        .then(setMyTestimonial)
        .then(() => setMode("Update"))
        .catch((err) => console.log(err));
    }
  }, []);
  useEffect(window.scrollEffect, []);

  useEffect(() => {
    window.setTimeout(() => setResponse({ message: "" }), 5000);
  }, [response]);

  const onSave = (e) => {
    const frm = e.currentTarget;
    e.preventDefault();
    frm.classList.add("was-validated");
    if (frm.checkValidity() === false) {
      return false;
    }
    let frmdata = new FormData(frm);
    frmdata.append("testimonial", _.get(mytestimonial, "testimonial", ""));
    setSaving(true);
    setServerData("trainer/testimonials ", frmdata).then((res) => {
      setSaving(false);
      setResponse(res);
      props.onSave();
      props.onClose();
    });
  };

  const renderForm = () => (
    <Form onSubmit={onSave} noValidate className="form contact alertdesign needs-validation">
      <Form.Control type="hidden" name="id" defaultValue={_.get(mytestimonial, "id", "")} />

      <Row>
        <Col md={12} className="mt-3">
          <Form.Label>Author Information: </Form.Label>
          <Form.Control
            type="text"
            name="author"
            placeholder="Enter Author Information*"
            defaultValue={_.get(mytestimonial, "author", "")}
            required
          />
          <div className="invalid-feedback">Author Information is required!</div>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="mt-3">
          <Form.Label>Description: </Form.Label>
          <TextEditor value={_.get(mytestimonial, "testimonial", "")} onChange={onContentChange("testimonial")} />
        </Col>
      </Row>

      <Row>
        <Col md={12} className="mt-3 text-right">
          {saving && (
            <>
              Saving.. <Spinner animation="border" />
            </>
          )}
          {!saving && response.message === "" && (
            <>
              <Button type="submit" className="profile-save">
                Save
              </Button>
              <span className="clickable btn btn-secondary ml-2" onClick={props.onClose}>
                Go back to listing
              </span>
            </>
          )}
          {!saving && response.message !== "" && (
            <Alert variant={response.success ? "info" : "danger"} className="p-3 mt-2 text-center">
              {response.message}
            </Alert>
          )}
        </Col>
      </Row>
    </Form>
  );

  const renderModal = () => (
    <Modal show={true} size="xl" onHide={_.get(props, "onClose", "")}>
      <Modal.Header closeButton>
        <Modal.Title>{mode} Testimonial </Modal.Title>
      </Modal.Header>

      <Modal.Body>{renderForm()}</Modal.Body>
    </Modal>
  );

  return (
    <>
      {props.type !== "modal" && renderForm()}
      {props.type === "modal" && renderModal()}
    </>
  );
};

export default TestimonialForm;
