import { useEffect, useContext, useState } from "react";
import { Form, Alert, Spinner, Row, Col, Button } from "react-bootstrap";
import UserContext from "./../../contexts/UserContext";
import TextEditor from "../TextEditor";
import _ from "lodash";
import { Modal, ModalHeader } from "reactstrap";
import TeacherAbout from "../../components/teacher/TeacherAbout";
import Utils from "../../Utils";
import Loader from "../Loader";

const EmailSettingForm = (props) => {
  const [smtp, setSmtp] = useState({});
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(0); // 0: no show, 1: show yes, 2: show no.
  const [saving, setSaving] = useState(false);
  const [response, setResponse] = useState({ success: false, message: "" });
  const { getServerData, setServerData, addToUserData } = useContext(UserContext);

  const [trainer, setTrainer] = useState({});

  const loadMySMTP = () => {
    setLoading(true);
    getServerData("trainer/my-smtp")
      .then(setSmtp)
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(loadMySMTP, []);

  const clearMessage = () => {
    window.setTimeout(() => setResponse({ message: "" }), 5000);
  };

  const onSave = (e) => {
    const frm = e.currentTarget;
    e.preventDefault();
    let frmdata = new FormData(frm);
    setSaving(true);
    setServerData("trainer/my-smtp", frmdata)
      .then((res) => {
        setSaving(false);
        setResponse(res);
        clearMessage();
      })
      .then(loadMySMTP)
      .catch((e) => {
        setResponse({ success: false, message: "Data not saved, please try again!" });
        clearMessage();
      });
  };

  useEffect(() => {
    setStatus(parseInt(_.get(smtp, "id", 0)) > 0 ? 1 : 0);
  }, [smtp]);

  const verifySMTP = (e) => {
    let frm = e.currentTarget.form;
    let frmdata = new FormData(frm);
    setSaving(true);
    setServerData("trainer/my-smtp-verify", frmdata, "post")
      .then((res) => {
        setResponse(res);
      })
      .catch((e) => setResponse({ success: false, message: "Failed to verify, please try again!" }))
      .finally(() => {
        setSaving(false);
        clearMessage();
      });
  };

  const renderSmtpFields = () => {
    return (
      <>
        <Row>
          <Col md={12} className="mt-3">
            <Form.Label>From Email Address: </Form.Label>
            <Form.Control type="email" name="femail" placeholder="Enter smtp from email address" required defaultValue={_.get(smtp, "femail", "")} />
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mt-3">
            <Form.Label>SMTP Host (Server host): </Form.Label>
            <Form.Control type="text" name="host" placeholder="Enter smtp host" required defaultValue={_.get(smtp, "host", "")} />
          </Col>
          <Col md={6} className="mt-3">
            <Form.Label>SMTP Port (TLS: 587 / SSL: 465): </Form.Label>
            <Form.Control type="number" name="port" placeholder="Enter smtp port" required defaultValue={_.get(smtp, "port", "")} />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mt-3">
            <Form.Label>SMTP Username (email account): </Form.Label>
            <Form.Control type="text" name="username" placeholder="Enter smtp username" required defaultValue={_.get(smtp, "username", "")} />
          </Col>
          <Col md={6} className="mt-3">
            <Form.Label>SMTP Password: </Form.Label>
            <Form.Control type="password" name="password" placeholder="Enter smtp password" required />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <>
      {loading === true && <Loader />}
      {loading === false && (
        <Form onSubmit={onSave}>
          <Form.Control type="hidden" name="id" defaultValue={_.get(smtp, "id", "")} />
          <div className="container">
            <h3 className="lineANimation ">Email Settings</h3>
            <div className="mt-3">
              <input className="emailradio ks" type="radio" name="mode" value="kssmtp" checked={status === 0} onChange={(e) => setStatus(0)} />
              <b>System default Email Service</b> <br />
              <div className="mt-1">
                <input
                  className="emailradio ks custom mt-2"
                  type="radio"
                  name="mode"
                  value="customsmtp"
                  checked={status === 1}
                  onClick={(e) => setStatus(1)}
                />
                <b>Custom Email Service</b>
              </div>
              {status === 1 && renderSmtpFields()}
            </div>
            <Row>
              <Col md={12} className="mt-3 text-right">
                {saving && (
                  <>
                    Processing.. <Spinner animation="border" />
                  </>
                )}
                {!saving && response.message === "" && (
                  <>
                    {status === 1 && (
                      <Button type="button" className="btn btn-danger mx-2" onClick={verifySMTP}>
                        <i className="mr-2 fa fa-server"></i>
                        Verify SMTP Connection
                      </Button>
                    )}
                    <Button type="submit" className="profile-save">
                      Save
                      <i className="ml-2 fa fa-save"></i>
                    </Button>
                  </>
                )}
                {!saving && response.message !== "" && (
                  <Alert variant={response.success ? "info" : "danger"} className="p-3 mt-2 text-center">
                    {response.message}
                  </Alert>
                )}
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </>
  );
};
export default EmailSettingForm;
