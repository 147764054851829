import React, { useEffect, useState, useContext, cloneElement } from "react";
import { Container } from "react-bootstrap";
import { HeaderTrainer, Footer, JoinAsStudent, JoinAsTrainer, JoinAsCorporate } from "./../components";
import Utils from "../Utils";
import UserContext from "./../contexts/UserContext";

const StaticPageLayout = ({ children }) => {
  const [sitesetting, setSitesetting] = useState({});
  const { getServerData } = useContext(UserContext);
  const callbackfn = () => {
    if (Utils.hasSubdomain()) {
      getServerData(`trainer/profiledata/${Utils.subdomain()}`).then((data) => {
        if (data.user_id > 0) {
          getServerData(`settings/trainer/${data.user_id}`).then((res) => {
            setSitesetting({ ...sitesetting, ...res.data, ...data });
          });
        }
      });
    } else {
      getServerData(`settings/trainer/0`).then((res) => {
        setSitesetting(res.data);
      });
    }
  };
  useEffect(callbackfn, []);
  useEffect(
    function () {
      if (sitesetting.company_name !== undefined && sitesetting.company_name !== "") {
        document.querySelectorAll("head title")[0].innerText = sitesetting.company_name;
      }
      console.log(sitesetting);
      if (sitesetting.favicon !== undefined && sitesetting.favicon !== "") {
        document.querySelectorAll("link[rel=icon]")[0].href = `${process.env.REACT_APP_API_URL}/uploads/favicon/${sitesetting.favicon}`;
      }
    },
    [sitesetting]
  );
  return (
    <Container fluid className="h-100 p-0">
      {sitesetting && <HeaderTrainer sitesetting={sitesetting} />}
      {/* <LoginModal /> */}
      <JoinAsStudent />
      <JoinAsTrainer />
      <JoinAsCorporate />
      {cloneElement(children, { sitesetting: sitesetting })}
      {sitesetting && <Footer sitesetting={sitesetting} />}
    </Container>
  );
};

export default StaticPageLayout;
