import Layout from "./layouts";
import Page from "./pages";

const CustomRoutes = [
  /*{
    path: "/login",
    secure: false,
    exact: true,
    layout: Layout.DefaultLayout,
    component: Page.Home,
  },*/
  {
    path: "/login",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.LoginPage,
  },
  {
    path: "/about-us",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.About,
  },
  {
    path: "/blogs",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.Blogs,
  },
  {
    path: "/blogs/:slug",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.BlogsDetails,
  },
  {
    path: "/blogs-details",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.BlogsDetails,
  },
  {
    path: "/ad-studio",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.ADStudio,
  },
  {
    path: "/ad-trainer",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.ADTrainer,
  },
  {
    path: "/site-settings",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.SiteSettings,
  },

  {
    path: "/my-profile",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.MyProfile,
  },
  {
    path: "/my-profile/*",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.MyProfile,
  },
  {
    path: "/manage-products",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.ManageProducts,
  },
  {
    path: "/my-pages",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.MyPages,
  },
  {
    path: "/my-blocks",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.MyBlocks,
  },
  {
    path: "/my-blocks/:pgid",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.MyBlocks,
  },
  {
    path: "/manage-coupons",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.ManageCoupons,
  },

  {
    path: "/my-student",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.MyStudent,
  },
  {
    path: "/my-blog",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.MyBlog,
  },
  {
    path: "/my-testimonial",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.MyTestimonial,
  },
  {
    path: "professional-profile/:page",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.TeacherLanding,
  },
  {
    path: "/professional-profile",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    //component: Page.LandingPage,
    component: Page.TeacherLanding,
  },
  {
    path: "/trainers/:slug/:blogs/:slug",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.BlogDetails,
  },
  {
    path: "/trainers/:slug",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    /*component: Page.TeacherLanding,*/
    component: Page.LandingPage,
  },
  {
    path: "/products/:slug",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.ProductDetails,
  },
  {
    path: "/search-results",
    secure: false,
    exact: true,
    layout: Layout.DefaultLayout,
    component: Page.SearchResult,
  },
  {
    path: "/help-for-student",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.ADStudent,
  },
  {
    path: "/payment/success/:id",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.PaymentSuccess,
  },
  {
    path: "/paypal/:mode",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.PaypalProcess,
  },
  {
    path: "/my-cart",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.MyCart,
  },
  {
    path: "/checkout",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.Checkout,
  },
  {
    path: "/student/my-profile",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.StudentProfileView,
  },
  {
    path: "/my-order",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.MyOrder,
  },
  {
    path: "/my-corporate-groups",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.MyCorporateGroup,
  },
  {
    path: "/my-corporate-groups/:cgid",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.CorporateGroupDetail,
  },
  {
    path: "/my-sales",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.MySales,
  },
  {
    path: "/student/my-profile/edit",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.StudentProfile,
  },
  {
    path: "/contact-us",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.ContactUs,
  },
  {
    path: "/cookie-policy",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.CookiePolicy,
  },
  {
    path: "/privacy-policy",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.PrivacyPolicy,
  },
  {
    path: "/resetpass/:token",
    secure: false,
    exact: true,
    layout: Layout.SwitchLayout,
    component: Page.ResetPassword,
  },
  {
    path: "/chgpwd",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.ChangePassword,
  },
  {
    path: "/term-conditions",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.TermConditions,
  },
  {
    path: "/preferred-trainers",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.PreferredTrainers,
  },
  {
    path: "/preferred-products",
    secure: true,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.PreferredProducts,
  },
  {
    path: "/readls",
    secure: false,
    exact: true,
    layout: Layout.DefaultLayout,
    component: Page.ReadLocalStorage,
  },
  {
    path: "/:slug",
    secure: false,
    exact: true,
    layout: Layout.StaticPageLayout,
    component: Page.TrainerPage,
  },
  {
    path: "/register/:cid",
    secure: false,
    exact: true,
    layout: Layout.DefaultLayout,
    component: Page.TrainerPage,
  },
  {
    path: "/",
    secure: false,
    exact: true,
    layout: Layout.DefaultLayout,
    component: Page.TrainerPage,
  },
  {
    path: "*",
    secure: false,
    exact: true,
    layout: Layout.DefaultLayout,
    component: Page.Home,
  },
];

export default CustomRoutes;
