import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Container, Tab, Row, Col, Button } from "react-bootstrap";
import BlogForm from "../components/blogs/BlogForm";
import DataTableGrid from "../components/DataTableGrid";
import axios from "axios";
import moment from "moment";
import Utils from "../Utils";
import UserContext from "./../contexts/UserContext";
import TestimonialForm from "../components/testimonials/TestimonialForm";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MyTestimonial = (props) => {
  const { apiHeaders } = useContext(UserContext);
  const [showmc, setShowmc] = useState({ show: false, row: null, type: null });
  const { getServerData, setServerData, getUserData } = useContext(UserContext);
  const [list, setList] = useState({ loading: false, error: false, pageInfo: {}, data: [] });
  const [showForm, setShowForm] = useState({ id: false, mode: 0 }); // 0=do not show, 1=add, 2=edit
  const MySwal = withReactContent(Swal);

  const listColumns = ["id", "author", "created_at", "is_active"];

  const columns = listColumns.map((v) => ({
    name: v.toUpperCase(),
    selector: (row) => row[v],
    format: (row) => {
      if (v === "created_at") {
        return moment(row[v]).format("DD MMM YYYY");
      }
      return row[v];
    },
    omit: v === "is_active",
    sortable: true,
    width: v === "id" ? "70px" : v === "author" ? "" : "200px",
  }));

  const toggleTestimonial = (tid, checked) => {
    if (window.confirm(`You are going to ${checked ? "ENABLE" : "DISABLE"} this page\n\n Continue?`)) {
      setList({ ...list, loading: true, message: `${checked ? "Enabling" : "Disabling"} the page...` });
      setServerData("trainer/testimonials", `id=${tid}&is_active=${checked ? 1 : 0}`)
        .then((res) => {
          if (res.success) {
            let data = list.data.map((row, idx) => ({
              ...row,
              is_active: row.id === tid ? checked : row.is_active,
            }));
            setList({ ...list, loading: false, data: data });
          } else {
            setList({ ...list, loading: false });
          }
        })
        .catch((e) => setList({ ...list, loading: false }));
    }
  };

  columns.push({
    name: "Action",
    cell: (row) => (
      <>
        {/* <Button size='sm' variant="light" className="mr-1" onClick={()=>setShowForm({mode: 2, slug: row.slug})}><i className="fa fa-eye" /></Button> */}
        <Button size="md" variant="light" className="mr-1 mt-0 p-0" onClick={() => setShowForm({ mode: 2, id: row.id })}>
          <i className="fa fa-edit" />
        </Button>
        <Button size="md" variant="light" className="mr-1 mt-0 p-0" onClick={deleteRecord(row.id)}>
          <i className="fa fa-trash text-danger" />
        </Button>
        <span
          className={`clickable fas mt-1 font-18 mx-1 p-0 ${row.is_active ? "fa-check-circle text-success" : "fa-times-circle text-secondary"}`}
          title={`${row.is_active ? "Disable" : "Enable"} this testimonial`}
          onClick={() => toggleTestimonial(row.id, !row.is_active)}
        />
      </>
    ),
    sortable: false,
    width: "130px",
  });

  const deleteRecord = (id) => (e) => {
    MySwal.fire({
      title: 'You are going to delete record?',
      text: "Are you sure to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0f79aa',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success',
          axios.delete(Utils.apiUrl(`trainer/testimonials/${id}`), apiHeaders()).then((res) => {
            fetchList();
          })
        )
      }
    })
  };

  const fetchList = () => {
    setList({ ...list, loading: true });
    axios.get(Utils.apiUrl("trainer/testimonials"), apiHeaders()).then((res) => {
      if (res.data.success) {
        setList({ ...list, loading: false, error: false, pageInfo: res.data.pageInfo, data: res.data.data.map((v) => _.pick(v, listColumns)) });
      } else {
        setList({ ...list, loading: false, error: res.data.message, pageInfo: {}, data: [] });
      }
    });
  };
  useEffect(window.scrollEffect, []);
  useEffect(fetchList, []);

  return (
    <>
      <Container fluid className="h-100 p-0">
        <div className="profile-wrapper">
          <div className="container mysale myblog">
            <Tab.Container id="left-tabs-example">
              <div className="card-header ui-sortable-handle d-flex">
                <h3 className="">My Testimonials</h3>
                <span className="my-auto ml-auto">
                  <button className="btn btn-primary-outline bg-primary text-light btn-sm" onClick={() => setShowForm({ mode: 1, id: false })}>
                    Add Testimonial <i className="fas fa-plus"></i>
                  </button>
                </span>
              </div>
              {showForm.mode === 0 && (
                <Row>
                  <Col sm={12}>
                    <DataTableGrid columns={columns} data={list.data} />
                  </Col>
                </Row>
              )}
            </Tab.Container>

            {showForm.mode > 0 && (
              <TestimonialForm type="form" id={showForm.id} onClose={() => setShowForm({ ...showForm, mode: 0 })} onSave={fetchList} />
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default MyTestimonial;
