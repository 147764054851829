import { useEffect, useContext, useState } from "react";
import { Form, Alert, Spinner, Row, Col, Button } from "react-bootstrap";
import UserContext from "./../../contexts/UserContext";
import _ from "lodash";

const CalibForm = (props) => {
  const [pa, setPA] = useState([]);
  const [myc, setMyc] = useState([]);
  const [saving, setSaving] = useState(false);
  const [response, setResponse] = useState({ success: false, message: "" });
  const { getServerData, setServerData } = useContext(UserContext);
  const [dataarray, setDataarray] = useState([]);

  useEffect(() => {
    getServerData("profile_attributes")
      .then(setPA)
      .then(() => getServerData("trainer/my-calibs?limit=999999"))
      .then(setMyc)
      .catch((err) => console.log(err));
  }, []);
  useEffect(window.scrollEffect, []);

  useEffect(() => {
    window.setTimeout(() => setResponse({ message: "" }), 5000);
  }, [response]);

  const [dFilters, setdFilters] = useState({
    18: { id: 18, parent_id: 1, children: [] },
    44: { id: 44, parent_id: 25, children: [] },
  });

  let queueCalling = [];

  useEffect(() => {
    _.each(dFilters, (d) => {
      let myc_pa = parseInt(
        _.get(
          _.find(myc, (m) => m.pa_id === d.parent_id),
          "pa_value",
          0
        )
      );
      if (myc_pa > 0) {
        queueCalling.push([myc_pa, d.parent_id]);
      }
    });
    callQueue();
  }, [myc]);

  const callQueue = () => {
    if (queueCalling.length > 0) {
      let p = queueCalling[0];
      populateSubFilter(p[0], p[1]);
      queueCalling.shift();
      window.setTimeout(callQueue, 500); //=== queue calling magic
    }
  };

  const onFilterChange = (e) => {
    let vFilter = parseInt(e.target.value);
    let parentId = parseInt(e.target.getAttribute("myid"));
    populateSubFilter(vFilter, parentId);
  };

  const populateSubFilter = (vFilter, parentId) => {
    console.log("calling: ", vFilter, " ParentId: ", parentId);
    let dfIdx = _.get(_.find(dFilters, { parent_id: parentId }), "id", "");
    let tmp = { ...dFilters };
    let chld = null;
    if (vFilter === "") {
      _.set(tmp, dfIdx, { id: dfIdx, parent_id: parentId, children: [] });
    } else {
      let allChildren = _.flatten(_.map(pa, (a) => a.children));
      vFilter = _.find(allChildren, { id: parseInt(vFilter) });
      if (parseInt(_.get(vFilter, "parent_id", 0)) > 0) {
        chld = _.get(vFilter, "children", []);
        _.set(tmp, dfIdx, { id: dfIdx, parent_id: vFilter.parent_id, children: chld });
      } else {
        _.set(tmp, dfIdx, { id: dfIdx, parent_id: parentId, children: [] });
      }
    }

    setdFilters(tmp);
  };

  const onSave = (e) => {
    const frm = e.currentTarget;
    e.preventDefault();
    let frmdata = new FormData(frm);
    setSaving(true);
    setServerData("trainer/my-calibs", frmdata).then((res) => {
      setSaving(false);
      setResponse(res);
    });
  };

  const isMyc = (pid, pval) => {
    return (
      _.get(
        _.find(myc, (r) => r.pa_id === pid && r.pa_value === pval),
        "id",
        false
      ) !== false
    );
  };
  const handleOptionChange = (event) => {
    let selectedId = Number(event.target.id);
    let selectedValue = Number(event.target.value);
    //alert('selectedValue'+selectedValue);
    pa.map((item) => {
      //return item.title; print industries, sub industries n all
      //return item.children; it returns object
      let items = item.children;
      //alert(items);
      items.map((newItem) => {
        //alert(newItem.title); //prints agriculture, IT , mining
        let newItems = newItem.children;
        let dataarray = _.filter(newItems, (rec) => Number(rec.parent_id) === selectedValue);
        //alert('grandChildren'+JSON.stringify(dataarray));
        if (dataarray.length > 0) {
          setDataarray(dataarray);
        }
      });
    });
  };

  const renderPA_old = () => {
    const SubIndustryText = "Sub Industry";
    return pa.map((p) => (
      <Col md={p.id === 0 ? 12 : 6} key={p.title} className="mt-3">
        <Form.Label>{p.title}</Form.Label>
        <Form.Control as="select" multiple name={`calib[${p.id}][]`} id={p.id} onChange={handleOptionChange}>
          {_.get(p, "children.length", 0) > 0 &&
            p.children.map((pc) => (
              <option key={pc.id} value={pc.id} selected={isMyc(p.id, pc.id)}>
                {pc.title}
              </option>
            ))}
        </Form.Control>
        {p.id === 1 && false ? (
          <div>
            <Form.Label className="mt-3">{SubIndustryText}</Form.Label>
            <Form.Control as="select" name={`calib[${p.id}][]`}>
              {dataarray.map((arrayvalues) => (
                <option key={arrayvalues.id} value={arrayvalues.id} selected={isMyc(p.id, arrayvalues.id)}>
                  {arrayvalues.title}
                </option>
              ))}
            </Form.Control>
          </div>
        ) : (
          <p></p>
        )}
      </Col>
    ));
  };

  const renderPA = (p) => {
    let dfilter = _.get(dFilters, p.id, {});
    let children = _.get(p, "children", []);
    if (_.get(dfilter, "children.length", 0) > 0) {
      children = dfilter.children;
    }
    return (
      <div className="col-md-6 col-lg-6 pt-1 pb-2 mb-4">
        <div className="hmesrch p-2" key={p.title}>
          <div className="alert bg-light text-dark filterbgcolor w-100">
            <b>{p.title}</b>
          </div>
          <div id={`container_${p.id}`}>
            {children.length === 0 && (
              <div className="text-center">
                {_.get(dfilter, "children.length", 0) === 0 && <>Please select {_.find(pa, { id: dfilter.parent_id }).title}</>}
              </div>
            )}

            {children.length > 0 && (
              <select className="selectClass" name={`pa_${p.id}`} myid={p.id} size={5} onChange={onFilterChange}>
                {children.map((pc) => (
                  <option
                    className="p-1"
                    key={pc.id}
                    value={pc.id}
                    selected={
                      parseInt(
                        _.get(
                          _.find(myc, (m) => m.pa_id === p.id),
                          "pa_value",
                          0
                        )
                      ) === pc.id
                    }
                  >
                    {pc.title}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Form onSubmit={onSave}>
      <Row>
        <Col md={12}>
          <h3 className="MobileHeader lineANimation ">Trainer Attribute Details</h3>
        </Col>
        {pa.map(renderPA)}
        <Col md={12} className="text-right">
          {saving && (
            <>
              Saving.. <Spinner animation="border" />
            </>
          )}
          {!saving && response.message === "" && (
            <Button type="submit" className="profile-save">
              Save
            </Button>
          )}
          {!saving && response.message !== "" && (
            <Alert variant={response.success ? "info" : "danger"} className="p-3 mt-2 text-center">
              {response.message}
            </Alert>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default CalibForm;
