import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Utils from "../../Utils";
import UserContext from "../../contexts/UserContext";
import _ from "lodash";
import moment from "moment";
import { Loader } from "..";

const AcademicBlock = (props) => {
  const { getServerData, getUserData } = useContext(UserContext);
  const slug = Utils.hasSubdomain() ? Utils.subdomain() : getUserData().slug;
  const [tData, setTData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    getServerData(`trainer/blocks/${slug}?mode[]=academics`, true)
      .then((tData) => {
        setTData(tData);
        setLoading(false);
      })
      .catch((msg) => {
        setTData({ success: false, message: msg });
        setLoading(false);
      });
  };

  useEffect(fetchData, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="profileContent tab-pane active mt-4 mb-4" id="academicContent">
          <div className="">
            <div className="">
              {/* <div className="awardTextInner"> */}
              <div className="">
                {tData.academics.map((a) => (
                  <div className="AboutawadText">
                    <span className="AboutawardYear">{a.year}</span> <span>{a.qualification}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AcademicBlock;
