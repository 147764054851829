import CalibForm from "./CalibForm";
import AboutForm from "./AboutForm";
import AcademicForm from "./AcademicForm";
import ExperienceForm from "./ExperienceForm";
import AwardCertificationsForm from "./AwardCertificationsForm";
import LibraryForm from "./LibraryForm";
import SocialForm from "./SocialForm";
import SliderForm from "./SliderForm";
import EventsForm from "./EventsForm";
import LandingpageForm from "./LandingpageForm";
import BlogForm from "./BlogForm";
import EmailSettingForm from "./EmailSettingForm";

export default {
  CalibForm,
  AboutForm,
  AcademicForm,
  ExperienceForm,
  AwardCertificationsForm,
  LibraryForm,
  SocialForm,
  SliderForm,
  EventsForm,
  LandingpageForm,
  BlogForm,
  EmailSettingForm,
};
