import React, { useEffect, useState, useContext } from "react";
import { Container, Spinner, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import Utils from "../Utils";
import { Loader } from "../components";
import UserContext from "./../contexts/UserContext";


const BlogsDetails = (props) => {
    const { apiHeaders } = useContext(UserContext);
    const { slug } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const { getServerData } = useContext(UserContext);
    const [list, setList] = useState({ loading: false, error: false, pageInfo: {}, data: [] });
    const [filters, setFilters] = useState({ where: { active: 1 }, limit: 4, start: 0 });

    useEffect(() => {
        getServerData(`blog/main-blog/${slug}`, true)
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((msg) => {
                setData({ success: false, message: msg });
                setLoading(false);
            });
    }, []);

    useEffect(window.scrollEffect, [loading]);

    const fetchList = () => {
        setList({ ...list, loading: true });
        let params = `?where[blog_status]=1&limit=${filters.limit}&start=${filters.start}&`;
        axios.get(Utils.apiUrl('blog/main-blog' + params), apiHeaders()).then((res) => {
            if (res.data.success) {
                setList({ ...list, loading: false, error: false, pageInfo: res.data.pageInfo, data: res.data.data });
            } else {
                setList({ ...list, loading: false, error: res.data.message, pageInfo: {}, data: [] });
            }
        });
    };
    useEffect(fetchList, [filters]);

    useEffect(window.scrollEffect, []);

    return (<>
        <Container className="h-100 p-0">
            {loading && (
                <>
                    <>
                        <Loader />
                    </>
                </>
            )}
            {!loading && (
                <>
                    <div className="profile-wrapper">
                        <div className="container">
                            <h3 className="text-uppercase lineANimation">Blog Details</h3>
                            <div className='pt-5'>
                                <div className='row'>
                                    {_.get(data, "success", false) !== false && (
                                        <>
                                            <div className='col-lg-9 col-md-9 col-12'>
                                                <div className="slideInUp wow ksblog border p-3">
                                                    <div className="bgblog">
                                                        <img className="img-fluid w-100 rounded" src={`${process.env.REACT_APP_API_URL}/uploads/mainblog/banner/${data.banner_image}`} alt="Tverse" />
                                                        <ul class="ksicon">
                                                            <li><a href="#"><i class="fab fa-facebook"></i></a></li>
                                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                                            <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                                                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h3 className='mt-3 mb-3'>{data.name}</h3>
                                                        <div className='' dangerouslySetInnerHTML={{ __html: data.description }}></div>
                                                        <p className='blogcreate'>Created on:- {moment(data.created_at).format('lll')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className='col-lg-3 col-md-3 col-12'>
                                        {/* <div className="slideInUp wow ksblog border p-3">
                                    <a href='#' className='mt-2 mb-2'>
                                        <div className='row d-flex align-center'>
                                            <div className='col-lg-4 col-md-4 col-12'>
                                                <div className="bgblog">
                                                    <img className="img-fluid w-100 rounded" src="../assets/images/full-user-1.png" alt="Tverse" />
                                                </div>
                                            </div>
                                            <div className='col-lg-8 col-md-8 col-12'>
                                                <div>
                                                    <h6 className='mb-1'>Blog Title</h6>
                                                    <p className='sidesrtdescptn'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div> */}


                                        {list.data.map(record => <div className="slideInUp wow ksblog border p-3 mt-2 mb-2">
                                            <a href={`${process.env.PUBLIC_URL}` + record.slug} className='mt-2 mb-2'>
                                                <div className='row d-flex align-center'>
                                                    <div className='col-lg-4 col-md-4 col-12'>
                                                        <div className="bgblog">
                                                            <img className="img-fluid w-100 rounded" src={`${process.env.REACT_APP_API_URL}/uploads/mainblog/blog/${record.blog_image}`} alt={record.name} />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-8 col-md-8 col-12'>
                                                        <div>
                                                            <h6 className='mb-1'>{record.name}</h6>
                                                            <p className='sidesrtdescptn' dangerouslySetInnerHTML={{ __html: record.short_description }}></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Container>

    </>);
};

export default BlogsDetails;