import React, { useEffect, useState, useContext } from "react";
import { Container } from "react-bootstrap";
import { Header, Footer } from "./../components";
import Utils from "../Utils";

import UserContext from "./../contexts/UserContext";

const LoginLayout = ({ children }) => {
  const [sitesetting, setSitesetting] = useState({});
  const { getServerData } = useContext(UserContext);
  const callbackfn = () => {
    if (Utils.hasSubdomain()) {
      getServerData(`trainer/profiledata/${Utils.subdomain()}`).then((data) => {
        if (data.user_id > 0) {
          getServerData(`settings/trainer/${data.user_id}`).then((res) => {
            setSitesetting({ ...sitesetting, ...res.data, ...data });
          });
        }
      });
    }
  };
  useEffect(callbackfn, []);
  return (
    <Container fluid className="h-100 ">
      {sitesetting && <Header sitesetting={sitesetting} />}
      {children}
      {sitesetting && <Footer sitesetting={sitesetting} />}
    </Container>
  );
};

export default LoginLayout;
