import React, { useEffect, useContext, useState } from "react";
import { Container, Alert, Spinner } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import UserContext from "../contexts/UserContext";
import _ from "lodash";
import { Loader } from "../components";

const PaypalProcess = (props) => {
  const { mode } = useParams();
  const [searchParams] = useSearchParams();

  const [udata, setuData] = useState({});
  const [loading, setLoading] = useState(mode === "success");
  const { getUserData, getServerData, isLoggedIn } = useContext(UserContext);
  const [processing, setProcessing] = useState(mode === "success" ? { mode: "info", msg: "Confirming your order, please wait.." } : false);

  const checkPaypalDetails = () => {
    if (mode === "success") {
      getServerData(`cart/checkpaypal/${searchParams.get("paymentId")}/${searchParams.get("PayerID")}`, true).then((res) => {
        if (res.success) {
          window.location.href = `/payment/success/${res.id}`;
        }
      });
    }
  };

  useEffect(checkPaypalDetails, []);

  useEffect(() => setuData(getUserData()), []);
  useEffect(window.scrollEffect, [loading]);

  return (
    <>
      <Container className="h-100 PaymentSuccess">
        {loading && <Loader />}

        {processing !== false && (
          <>
            <Alert variant={processing.mode}>
              <div className="m-5">{processing.msg}</div>
            </Alert>
          </>
        )}

        {!loading && (
          <>
            <div className="help-wrapper">
              <div className="container">
                {mode === "cancel" && <img src="/assets/images/cancellation-policy-banner.jpg" alt="Payment Cancelled" />}
                {mode === "success" && <img src="/assets/images/payment.png" alt="Payment Successful" />}
                <div className="AD-help">
                  <div className="row">
                    <div className="col-12 col-lg-2 col-md-12"></div>
                    <div className="col-12 col-lg-9 col-md-12 mt-5">
                      <div className="d-flex align-center Sucesmsgimg mt-5 mb-5">
                        <div className="row align-center">
                          {mode === "cancel" && (
                            <>
                              <div className="col-12 col-md-2 col-lg-2 text-center">
                                <img src="/assets/images/close-cross.png" alt="" />
                              </div>
                              <div className="col-12 col-md-10 col-lg-10">
                                <h1 className="successheading slideInUp wow text-left mt-0 text-danger">
                                  Hey, {udata.firstname} ! Your payment has been cancelled.
                                </h1>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-1 col-md-12"></div>
                  </div>
                  {mode === "cancel" && (
                    <div className="row mt-5 PaymentBtn">
                      <div className="col-12 col-md-4 col-lg-4 col-xl-6 text-left HomeRegister ">
                        <strong>
                          <a href={`${process.env.PUBLIC_URL}/`} className="">
                            <button> Home</button>
                          </a>
                        </strong>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4 col-xl-3 text-right HomeRegister">
                        <strong>
                          <a href={`${process.env.PUBLIC_URL}/search-results`} className="">
                            <button> Search Results</button>
                          </a>
                        </strong>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4 col-xl-3 text-right HomeRegister">
                        <strong>
                          <a href={`${process.env.PUBLIC_URL}/my-cart`} className="">
                            <button> My Cart</button>
                          </a>
                        </strong>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default PaypalProcess;
