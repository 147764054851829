import { useState, useEffect, useContext } from "react";
import UserContext from "../contexts/UserContext";
import { Container, Spinner, Alert, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import Utils from "../Utils";
import StarRatings from "react-star-ratings";
import { Loader } from "../components";

const ProductDetails = (props) => {
  const { slug } = useParams();

  const [product, setProduct] = useState({});

  const [loading, setLoading] = useState(true);
  const [starLoading, setStarLoading] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);
  const [rating, setRating] = useState({
    rating: 0,
    ratings: 0,
    enrollments: 0,
  });

  const { isLoggedIn, getServerData, setServerData, loginToMoodle, addToUserData, getUserData } = useContext(UserContext);

  const $ = window.$;

  useEffect(() => {
    getServerData(`product/${slug}`, true)
      .then((cData) => {
        setProduct(cData);

        setRating(cData.rating);
        setLoading(false);
      })
      .catch((msg) => {
        setProduct({ success: false, message: msg });
        setLoading(false);
      });
  }, []);

  const setProductRating = (rated) => {
    setStarLoading(true);
    let ratingData = new FormData();
    ratingData.append("product_id", product.product.id);
    ratingData.append("rating", rated);
    setServerData(`product/setRating`, ratingData, "post")
      .then((res) => {
        setStarLoading(false);
        setRating(res.success ? res.rating : product.rating);
      })
      .catch((msg) => {
        setStarLoading(false);
        setRating(product.rating);
        // do nothing
      });
  };

  useEffect(window.scrollEffect, [product]);

  const showBundlePrice = () => {
    let price = 0;
    _.forEach(getProductResource(product), (b) => (price += b.price));
    return price;
  };

  /*const bundleProduct = (resource) => {
    let tmp = [...bp];
    if (_.findIndex(tmp, (b) => b.id === resource.id) > -1) {
      tmp = _.filter(tmp, (b) => b.id !== resource.id);
    } else {
      tmp.push(resource);
    }
    setBp(tmp);
  };*/

  const addToCart = (e) => {
    e.preventDefault();
    let cartData = new FormData();
    cartData.append("product_id", product.product.id);
    cartData.append("product_resources", JSON.stringify(_.map(getProductResource(product), (b) => _.pick(b, ["id", "type", "name", "price"]))));
    cartData.append("price", showBundlePrice());
    cartData.append("is_bundle", parseInt(_.get(getProductResource(product), "length", 0)) > 0 ? 1 : 0);

    setAddingToCart(true);

    setServerData(`cart`, cartData, "post")
      .then((res) => {
        addToUserData({ cart: parseInt(_.get(getUserData(), "cart", 0)) + 1 });
        window.location.href = "/my-cart";
      })
      .catch((err) => console.error(err));
  };

  const renderResourceCircles = (product) => {
    const restype = {
      pdf: "pdf.png",
      PPT: "doc-icon.png",
      audio: "audio-icon.png",
      video: "video.png",
      webinar: "webinar.png",
      scorm: "scome.png",
    };

    let bresources = {};

    _.each(getProductResource(product), (pres) => {
      _.set(bresources, `${pres.type}.total`, _.get(bresources, `${pres.type}.total`, 0) + 1);
      _.set(bresources, `${pres.type}.price`, _.get(bresources, `${pres.type}.price`, 0) + pres.price);
    });

    return (
      <ul className="iconList nav nav-tabs">
        {_.map(bresources, (res, rtype) => (
          <li>
            <span class="badge bg-secondary text-white resttl">{res.total}</span>
            <div
              className={`circleBox wow zoomIn selected position-relative`}
              /*onClick={() => bundleProduct(resource)}*/
            >
              <img className="img-fluid" src={`/assets/images/${_.get(restype, rtype, "pdf.png")}`} style={{ width: "25px" }} />
              <span className="usdheading">USD {res.price}</span>
              <span className="usdtext mt-1">
                <span className="badge bg-secondary text-white text-uppercase">{rtype}</span>
              </span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const getProductResource = (product) => {
    return product.product.is_bundle ? _.flattenDeep(_.map(product.product.bundle_products, (bp) => bp.resources)) : product.resources;
  };

  const getProductDuration = (product) => {
    return product.product.is_bundle
      ? _.sum(_.map(product.product.bundle_products, (bp) => parseInt(bp.is_course ? bp.duration : 0)))
      : product.product.is_course
      ? product.product.duration
      : "";
  };

  const getProductlevel = (product) => {
    return product.product.is_bundle
      ? _.sum(_.map(product.product.bundle_products, (bp) => (bp.is_course === 1 ? bp.level : "")))
      : product.product.is_course
      ? product.product.level
      : " ";
  };

  const renderMediaResources = (product) => {
    const resources = getProductResource(product);
    return (
      <>
        {resources.length > 0 && (
          <div className="d-flex">
            <span className="textBold d-flex">Media: &nbsp; </span>
            {Utils.mediaTypes.map(
              (m) =>
                _.find(resources, {
                  type: m[0],
                }) && (
                  <span className="pr-2 d-flex align-center">
                    {m[1]} <img src={`/assets/images/${m[2]}`} className="ml-1" alt="AD" width="15" />
                  </span>
                )
            )}
          </div>
        )}
      </>
    );
  };

  const markFav = (product_id, fav) => (e) => {
    e.preventDefault();
    //$(e.target).fadeOut();
    setServerData("product/markfav", `product_id=${product_id}&fav=${fav}`, "post").then(() =>
      setProduct({
        ...product,
        isFav: !product.isFav,
      })
    );
    //.then(() => $(e.target).show())
  };

  const renderBundleProducts = (product) => {
    return (
      <>
        <h6 className="mt-2">This bundle contains below products: </h6>
        <div id={`bundle-${product.id}`}>
          {product.bundle_products.map((bp) => (
            <div class="card bundlecard">
              <div class="card-header bckg-blue">
                <a class="card-link libraryTitle text-white" data-toggle="collapse" href={`#bp-${bp.id}`}>
                  {bp.name}
                </a>
              </div>
              <div id={`bp-${bp.id}`} class="collapse">
                <div class="card-body p-3">
                  <Row>
                    <div className="col-4 text-center w-25 mt-2">
                      <img
                        className="img-fluid rounded"
                        src={`${process.env.REACT_APP_API_URL}/uploads/products/${bp.product_image}`}
                        alt={bp.name}
                      />
                    </div>
                    <div className="col-8 mt-2">
                      <div className="libraryBody" dangerouslySetInnerHTML={{ __html: bp.short_description }}></div>
                    </div>
                    <div className="col-8 mt-2">
                      <div className="d-flex">
                        <span className="textBold d-flex align-center">
                          <b>Media:</b> &nbsp;{" "}
                        </span>
                        {Utils.mediaTypes.map(
                          (m) =>
                            _.find(bp.resources, {
                              type: m[0],
                            }) && (
                              <span className="pr-2 d-flex align-center">
                                {m[1]} <img src={`/assets/images/${m[2]}`} className="ml-1" alt="AD" width="15" />
                              </span>
                            )
                        )}
                      </div>
                      <div className="mt-2">
                        <b>Date:</b> {new Date(bp.created_at).toLocaleDateString()}
                        <br />
                        {bp.is_course === 1 && (
                          <>
                            {" "}
                            <span className="textBold">
                              <b>Language:</b>
                            </span>{" "}
                            {bp.language} <b>|</b>{" "}
                          </>
                        )}
                        {bp.is_course === 1 && (
                          <span>
                            <b>Level:</b> {bp.level} <b>|</b> <b>Duration:</b> {bp.duration} Hours{" "}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-4 mt-2">
                      <a href={`/products/${bp.slug}`} className="btn btnBlue">
                        View Details
                      </a>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      <Container fluid className="h-100 p-0">
        {addingToCart && (
          <>
            <div className="profile-wrapper">
              <div className="container">
                <h1>Product</h1>
                <Alert variant="info">
                  <div className="m-5">
                    Adding product to cart <Spinner animation="border" size="sm" />
                  </div>
                </Alert>
              </div>
            </div>
          </>
        )}

        {loading && (
          <>
            <Loader />
          </>
        )}

        {!addingToCart && !loading && (
          <>
            {_.get(product, "success", false) === false && (
              <>
                <div className="profile-wrapper">
                  <div className="container">
                    <h1>Product</h1>
                    <Alert variant="danger">
                      <div className="m-5">{product.message}</div>
                    </Alert>
                  </div>
                </div>
              </>
            )}
            {_.get(product, "success", false) !== false && (
              <>
                <div className="cardWrapper">
                  <div className="container">
                    {renderResourceCircles(product)}
                    <div className="tab-content">
                      <div className="courseWrapper coursecard tab-pane active" id="PDF">
                        <div className="row">
                          <div className="col-md-6 wow slideInUp">
                            <div
                              className="imgWrapper rounded ViewProductImg"
                              style={{
                                backgroundImage: `url("${process.env.REACT_APP_API_URL}/uploads/products/${product.product.product_image}")`,
                              }}
                            >
                              <div className="circleBox">
                                <img className="img-fluid" src="/assets/images/bundle.png" alt="AD" />
                                <span className="usdheading active">USD {showBundlePrice()}</span>
                              </div>
                            </div>
                            <div className="textBoxCard">
                              <hr className="CourseDetailsHr"></hr>
                              <div className="cardInfoBox">
                                <span className="textBold">Created by</span> {product.about.firstname} {product.about.lastname}{" "}
                                <span className="textBold">| Last updated</span> {moment(product.product.created_at).format("DD/MM/YYYY")}
                                <br />
                                {renderMediaResources(product)}
                                {product.product.is_course === 1 && (
                                  <>
                                    {" "}
                                    <span className="textBold">Language:</span> {product.product.language} <b>|</b>{" "}
                                  </>
                                )}
                                {product.product.is_course === 1 && (
                                  <>
                                    {" "}
                                    <span className="textBold"> Level:</span> {getProductlevel(product)} <b>|</b>{" "}
                                  </>
                                )}
                                {(product.product.is_course === 1 || product.product.is_bundle === 1) && (
                                  <>
                                    <span className="textBold"> Duration:</span> {getProductDuration(product)} Hours
                                  </>
                                )}
                              </div>
                              {!starLoading && (
                                <div className="d-flex">
                                  <StarRatings
                                    rating={rating.rating}
                                    starEmptyColor="#dddddd"
                                    starRatedColor="#f3ac1b"
                                    starHoverColor="#bfa700"
                                    starDimension="20px"
                                    starSpacing="2px"
                                    changeRating={isLoggedIn() ? setProductRating : false}
                                  />
                                  <div className="mx-2 my-1">
                                    ({rating.ratings}) {rating.enrollments}{" "}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 slideInUp wow">
                            <div className="productOverview">
                              <h3>{product.product.name}</h3>
                              {(product.product.is_bundle === 1 || product.product.is_course === 1) && (
                                <span>
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}/uploads/products/${product.product.is_bundle ? "bundle" : "course"}.png`}
                                    className="img-fluid float-right"
                                    alt=""
                                    width={"75px"}
                                    height={"100px"}
                                  />
                                </span>
                              )}
                              <div
                                className="productinfo addReadMore showlesscontent"
                                dangerouslySetInnerHTML={{
                                  __html: product.product.description,
                                }}
                              ></div>
                              {product.product.is_bundle === 1 && (
                                <div
                                  className="productinfo addReadMore showlesscontent"
                                  dangerouslySetInnerHTML={{
                                    __html: product.product.short_description,
                                  }}
                                ></div>
                              )}
                              {product.product.is_bundle === 1 && renderBundleProducts(product.product)}
                              <form name="moodleLoginForm" method="post" action={`${process.env.REACT_APP_MOODLE_URL}/login/index.php`}>
                                <input type="hidden" name="username" />
                                <input type="hidden" name="password" />
                              </form>
                              <div className="productbtn">
                                <div className="row mt-4">
                                  <div className="col-lg-6">
                                    <div className="addButns">
                                      {!isLoggedIn() && (
                                        <>
                                          <a href={`/login`} className="btn btnBlue">
                                            Login to Enroll
                                          </a>
                                        </>
                                      )}
                                      {isLoggedIn() && (
                                        <>
                                          {product.product.is_enrolled === 1 ? (
                                            <>
                                              <span
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  loginToMoodle(document.forms.moodleLoginForm);
                                                }}
                                                className="clickable btn btnBlue"
                                              >
                                                Proceed To LMS
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              {product.product.is_visible === 1 && _.get(getProductResource(product), "length", 0) > 0 && (
                                                <a href="#" onClick={addToCart} className="btn btnBlue">
                                                  {product.is_course === 1 ? "Enroll" : "Buy"} Now
                                                </a>
                                              )}
                                            </>
                                          )}

                                          {product.product.is_visible === 1 && _.get(props, "sitesetting.preferred_products", 1) > 0 && (
                                            <a href="#" className="btn btnBorder" onClick={markFav(product.product.id, product.isFav ? 0 : 1)}>
                                              {product.isFav === false && (
                                                <span>
                                                  Mark Favourite <i className="far fa-heart ml-2" />
                                                </span>
                                              )}
                                              {product.isFav === true && (
                                                <span>
                                                  Remove Favourite <i className="fas fa-heart ml-2 text-danger" />
                                                </span>
                                              )}
                                            </a>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    {product.product.is_visible === 1 && _.get(getProductResource(product), "length", 0) > 0 && (
                                      <div className="coursePrice">USD {showBundlePrice()}</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="courseDesWrapper"></div>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default ProductDetails;
