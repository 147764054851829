import React, { useContext, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

const BlockTemplate = (props) => {
  return (
    <div className="mt-4 mb-4">
      {props.tid === "1" && (
        <Row>
          <Col sm={6} className="mt-4 mb-4">
            <div dangerouslySetInnerHTML={{ __html: props.left.value }}></div>
          </Col>
          <Col sm={6} className="mt-4 mb-4">
            <img src={`${process.env.REACT_APP_API_URL}/uploads/blocks/${props.right.value}`} className="img-fluid w-100" />
          </Col>
        </Row>
      )}
      {props.tid === "2" && (
        <Row>
          <Col sm={6} className="mt-4 mb-4">
            <img src={`${process.env.REACT_APP_API_URL}/uploads/blocks/${props.left.value}`} className="img-fluid w-100" />
          </Col>
          <Col sm={6} className="mt-4 mb-4">
            <div dangerouslySetInnerHTML={{ __html: props.right.value }}></div>
          </Col>
        </Row>
      )}
      {props.tid === "3" && (
        <Row>
          <Col sm={6} className="mt-4 mb-4">
            <div dangerouslySetInnerHTML={{ __html: props.left.value }}></div>
          </Col>
          <Col sm={6} className="mt-4 mb-4">
            <div dangerouslySetInnerHTML={{ __html: props.right.value }}></div>
          </Col>
        </Row>
      )}
      {props.tid === "4" && <img src={`${process.env.REACT_APP_API_URL}/uploads/blocks/${props.content.value}`} className="img-fluid w-100" />}
      {props.tid === "5" && <div dangerouslySetInnerHTML={{ __html: props.content.value }}></div>}
    </div>
  );
};

export default BlockTemplate;
