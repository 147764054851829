import { useEffect, useContext, useState } from "react";
import { Form, Alert, Spinner, Row, Col, Button } from "react-bootstrap";
import UserContext from "./../../contexts/UserContext";
import TextEditor from "../TextEditor";
import _ from "lodash";
import { Modal, ModalHeader } from "reactstrap";
import TeacherAbout from "../../components/teacher/TeacherAbout";
import Utils from "../../Utils";

const AboutForm = (props) => {
  const [myabout, setMyabout] = useState({});
  const [saving, setSaving] = useState(false);
  const [response, setResponse] = useState({ success: false, message: "" });
  const { getServerData, setServerData, addToUserData } = useContext(UserContext);

  const [trainer, setTrainer] = useState({});

  const loadAbout = () => {
    getServerData("trainer/my-about")
      .then(setMyabout)
      .catch((err) => console.log(err));
  };
  useEffect(loadAbout, []);
  useEffect(window.scrollEffect, []);

  useEffect(() => {
    window.setTimeout(() => setResponse({ message: "" }), 5000);
  }, [response]);

  const onSave = (e) => {
    const frm = e.currentTarget;
    e.preventDefault();
    let frmdata = new FormData(frm);
    setSaving(true);
    setServerData("trainer/my-about", frmdata)
      .then((res) => {
        setSaving(false);
        setResponse(res);
      })
      .then(loadAbout);
  };

  useEffect(() => addToUserData(_.pick(myabout, ["slug", "base_image", "firstname", "middlename", "lastname"])), [myabout]);
  const photoUploader = (fld, title) => {
    return (
      <>
        <Form.Label>{title}</Form.Label>
        <Form.Control type="file" size="lg" name={fld + "_image"} accept=".jpeg,.png,.PNG,.jpg;" />
        <div className="text-center">
          {!_.isEmpty(_.get(myabout, fld + "_image", "")) && (
            <img src={`${process.env.REACT_APP_API_URL}/uploads/${fld}/${myabout[fld + "_image"]}`} className="thumbnail mt-3" />
          )}
        </div>
      </>
    );
  };
  return (
    <>
      <Form onSubmit={onSave}>
        <Form.Control type="hidden" name="id" defaultValue={_.get(myabout, "id", "")} />
        <Form.Control type="hidden" name="old_base_image" defaultValue={_.get(myabout, "base_image", "")} />
        <Form.Control type="hidden" name="old_profile_image" defaultValue={_.get(myabout, "profile_image", "")} />
        <h3 className="lineANimation ">About Me</h3>
        <Row>
          <Col md={4} className="mt-3">
            <Form.Label>First Name: </Form.Label>
            <Form.Control type="text" name="firstname" placeholder="Enter your first name" defaultValue={_.get(myabout, "firstname", "")} />
          </Col>
          <Col md={4} className="mt-3">
            <Form.Label>Middle Name: </Form.Label>
            <Form.Control type="text" name="middlename" placeholder="Enter your middle name" defaultValue={_.get(myabout, "middlename", "")} />
          </Col>
          <Col md={4} className="mt-3">
            <Form.Label>Last Name: </Form.Label>
            <Form.Control type="text" name="lastname" placeholder="Enter your last name" defaultValue={_.get(myabout, "lastname", "")} />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3">
            <Form.Label>Trainer Profile URL: </Form.Label>
            <Form.Control type="text" name="slug" placeholder="Enter your slug" defaultValue={_.get(myabout, "slug", "")} />
          </Col>
          <Col md={6} className="mt-3">
            {photoUploader("base", "Upload Base Profile Pic")}
          </Col>
          <Col md={6} className="mt-3">
            {photoUploader("profile", "Upload Large Profile Pic")}
          </Col>
        </Row>

        <Row>
          <Col md={12} className="mt-3 text-right">
            {saving && (
              <>
                Saving.. <Spinner animation="border" />
              </>
            )}
            {!saving && response.message === "" && (
              <Button type="submit" className="profile-save">
                Save
              </Button>
            )}
            {!saving && response.message !== "" && (
              <Alert variant={response.success ? "info" : "danger"} className="p-3 mt-2 text-center">
                {response.message}
              </Alert>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default AboutForm;
