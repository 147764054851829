import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Container } from 'react-bootstrap';
import axios from "axios";
import moment from "moment";
import { Loader } from "../components";
import Utils from "../Utils";
import UserContext from "./../contexts/UserContext";

const Blogs = (props) => {
  const { apiHeaders } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState({ loading: false, error: false, pageInfo: {}, data: [] });
  const [filters, setFilters] = useState({ where: { active: 1 }, limit: 9, start: 0 });


  const fetchList = () => {
    setList({ ...list, loading: true });
    let params = `?where[blog_status]=1&limit=${filters.limit}&start=${filters.start}&`;
    axios.get(Utils.apiUrl('blog/main-blog' + params), apiHeaders()).then((res) => {
      if (res.data.success) {
        setList({ ...list, loading: false, error: false, pageInfo: res.data.pageInfo, data: res.data.data });
      } else {
        setList({ ...list, loading: false, error: res.data.message, pageInfo: {}, data: [] });
      }
    });
  };
  useEffect(() => console.log(list), [list])
  useEffect(window.scrollEffect, []);

  useEffect(fetchList, [filters]);

  const gotoPage = (page) => (e) => {
    const start = (page - 1) * filters.limit;
    setFilters({ ...filters, start: start });
  };

  return (<>
    <Container className="h-100 p-0">
      {list.loading === true && (
        <>
          <>
            <Loader />
          </>
        </>
      )}
      {list.loading === false && (
        <>
          <div className="profile-wrapper">

            <div className="container">
              <h3 className="text-uppercase lineANimation">Blogs</h3>

              <div className='pt-5'>
                <div className='row'>
                  {list.data.map(record => <div className='col-lg-4 col-md-6 col-12 mt-2 mb-2'>
                    <div className="slideInUp wow ksblog border p-3">
                      <a href={`${process.env.REACT_APP_PUBLIC_URL}/blogs/` + record.slug} target='_blank'>
                        <div className="bgblog">
                          <img className="img-fluid w-100 rounded" src={`${process.env.REACT_APP_API_URL}/uploads/mainblog/blog/${record.blog_image}`} alt={record.name} />
                          <ul class="ksicon">
                            <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_PUBLIC_URL}/blogs/` + record.slug} target="_blank"><i class="fab fa-facebook"></i></a></li>
                            <li><a href={`http://twitter.com/share?text=${record.name}&url=${process.env.REACT_APP_PUBLIC_URL}/blogs/${record.slug}`} target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href={`https://www.linkedin.com/shareArticle?mini=true&url=${process.env.REACT_APP_PUBLIC_URL}/blogs/` + record.slug + `&title=` + record.name} target="_blank"><i class="fab fa-linkedin"></i></a></li>
                            <li><a href={`whatsapp://send?text=${record.name}`} data-action="share/whatsapp/share" target="_blank"><i class="fab fa-whatsapp"></i></a></li>
                          </ul>
                        </div>
                        <div>
                          <h3 className='mt-3 mb-3 text-black text-center'>{record.name}</h3>
                          <p className='text-center srtdescptn' dangerouslySetInnerHTML={{ __html: record.short_description }}></p>
                          <p className='blogcreate'>Created on:- {moment(record.created_at).format('lll')}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
            {list.data.length > 0 && Utils.showPagination({ ...list.pageInfo, ..._.pick(filters, ['start', 'limit']) }, gotoPage)}
          </div>
        </>
      )}
    </Container>
  </>);
};

export default Blogs;