import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Container, Tab, Row, Col, Button } from "react-bootstrap";
import BlogForm from "../components/blogs/BlogForm";
import DataTableGrid from "../components/DataTableGrid";
import axios from "axios";
import moment from "moment";
import Utils from "../Utils";
import UserContext from "./../contexts/UserContext";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MyBlog = (props) => {
  const { apiHeaders } = useContext(UserContext);
  const [showmc, setShowmc] = useState({ show: false, row: null, type: null });

  const [list, setList] = useState({ loading: false, error: false, pageInfo: {}, data: [] });
  const [showForm, setShowForm] = useState({ id: false, mode: 0 }); // 0=do not show, 1=add, 2=edit
  const MySwal = withReactContent(Swal);

  const listColumns = ["id", "name", "created_at"];

  const columns = listColumns.map((v) => ({
    name: v.toUpperCase(),
    selector: (row) => row[v],
    format: (row) => {
      if (v === "created_at") {
        return moment(row[v]).format("DD MMM YYYY");
      }
      return row[v];
    },
    sortable: true,
    width: v === "id" ? "100px" : v === "name" ? undefined : "120px",
  }));

  columns.push({
    name: "Action",
    cell: (row) => (
      <>
        {/* <Button size='sm' variant="light" className="mr-1" onClick={()=>setShowForm({mode: 2, slug: row.slug})}><i className="fa fa-eye" /></Button> */}
        <Button size="sm" variant="light" className="mr-1" onClick={() => setShowForm({ mode: 2, id: row.id })}>
          <i className="fa fa-edit" />
        </Button>
        <Button size="sm" variant="light" className="mr-1" onClick={deleteRecord(row.id)}>
          <i className="fa fa-trash text-danger" />
        </Button>
      </>
    ),
    sortable: false,
    width: "100px",
  });

  const deleteRecord = (id) => (e) => {
    MySwal.fire({
      title: 'You are going to delete record?',
      text: "Are you sure to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0f79aa',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success',
          axios.delete(Utils.apiUrl(`trainer/my-blogs/${id}`), apiHeaders()).then((res) => {
            fetchList();
          })
        )
      }
    })
  };

  const fetchList = () => {
    setList({ ...list, loading: true });
    axios.get(Utils.apiUrl("trainer/my-blogs"), apiHeaders()).then((res) => {
      if (res.data.success) {
        setList({ ...list, loading: false, error: false, pageInfo: res.data.pageInfo, data: res.data.data.map((v) => _.pick(v, listColumns)) });
      } else {
        setList({ ...list, loading: false, error: res.data.message, pageInfo: {}, data: [] });
      }
    });
  };
  useEffect(window.scrollEffect, []);
  useEffect(fetchList, []);

  return (
    <>
      <Container fluid className="h-100 p-0">
        <div className="profile-wrapper">
          <div className="container mysale myblog">
            <Tab.Container id="left-tabs-example">
              <div className="card-header ui-sortable-handle">
                <h3 className="card-title">My Blogs </h3>
                <span className="btn float-right">
                  <button className="btn btn-primary-outline bg-primary text-light btn-sm" onClick={() => setShowForm({ mode: 1, id: false })}>
                    Add Blog <i className="fas fa-plus"></i>
                  </button>
                </span>
              </div>
              {showForm.mode === 0 && (
                <Row>
                  <Col sm={12}>
                    <DataTableGrid columns={columns} data={list.data} />
                  </Col>
                </Row>
              )}
            </Tab.Container>

            {showForm.mode > 0 && <BlogForm type="form" id={showForm.id} onClose={() => setShowForm({ ...showForm, mode: 0 })} onSave={fetchList} />}
          </div>
        </div>
      </Container>
    </>
  );
};

export default MyBlog;
