import React, { useContext, useEffect, useState } from "react";
import Utils from "./../Utils";
import _ from "lodash";
import UserContext from "./../contexts/UserContext";
import StarRatings from "react-star-ratings";
import { Loader } from "../components";

const SearchResult = (props) => {
  const [viewTrainer, setViewTrainer] = useState({});
  const [tData, setTData] = useState({
    loading: true,
    pageInfo: {},
    data: [],
    stats: {},
  });
  const [filters, setFilters] = useState({ start: 0, limit: 6 });
  const [loadStats, setLoadStats] = useState(true);
  const [isScrollTriggered, setIsScrollTriggered] = useState(false);

  const $ = window.$;

  const { getUserData, isLoggedIn, getServerData, setServerData } = useContext(UserContext);

  const fetchSearchResults = () => {
    let calibs = _.get(getUserData(), "calibs", []);
    let data = {};
    _.each(calibs, (pval, pk) => {
      if (pval != "") {
        data[pk] = parseInt(pval);
      }
    });

    setTData({ ...tData, loading: filters.start === 0 });

    getServerData(
      `trainer/search?calibs=${JSON.stringify(data)}&paCalibs=${Utils.searchCalibs.join(",")}&start=${filters.start}&limit=${filters.limit
      }&loadStats=${loadStats ? 1 : 0}`,
      true
    )
      .then((res) => {
        let tmp = {
          ...tData,
          loading: false,
          success: res.success,
          pageInfo: res.pageInfo,
        };
        if (_.get(res, "stats", false)) {
          tmp.stats = res.stats;
          setLoadStats(false);
        }

        tmp.data = _.concat(tmp.data, res.data);
        tmp.favTrainers = _.concat(tmp.favTrainers, res.favTrainers);

        setTData(tmp);

        setIsScrollTriggered(false);
      })
      .catch((e) => setTData({ ...tData, loading: false, success: false, message: e.message }));
  };

  useEffect(fetchSearchResults, [filters]);

  useEffect(() => {
    $(".circleChart").circleChart({
      color: "#6ecff6",
      backgroundColor: "#fff",
      background: true,
      size: 100,
    });
    if (parseInt(_.get(viewTrainer, "user_id", 0)) === 0) {
      setViewTrainer(_.get(tData, "data.0", {}));
    }
  }, [tData]);

  const handleScroll = (e) => {
    const bottom = Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) - e.target.clientHeight < 100;
    if (isScrollTriggered === false && bottom && filters.start + filters.limit < tData.pageInfo.total) {
      setIsScrollTriggered(true); //== this should avoid triggering load repeatative
      setFilters({ ...filters, start: filters.start + filters.limit });
    }
  };

  const markFav = (trainer_id) => (e) => {
    e.preventDefault();
    $(e.target).fadeOut();
    const data = {
      trainer_id: trainer_id,
      fav: tData.favTrainers.includes(trainer_id) ? 0 : 1,
    };
    setServerData("user/markfav", `trainer_id=${data.trainer_id}&fav=${data.fav}`, "post")
      .then(() =>
        setTData({
          ...tData,
          favTrainers: data.fav ? _.concat(tData.favTrainers, trainer_id) : _.filter(tData.favTrainers, (tid) => tid != trainer_id),
        })
      )
      .then(() => $(e.target).show());
  };

  const renderResultAnalysis = () => {
    const ratios = {
      trainers: parseInt(_.get(tData, "stats.trainers", 0)) / parseInt(_.get(tData, "stats.allTrainers", 0)),
      products: parseInt(_.get(tData, "stats.products", 0)) / parseInt(_.get(tData, "stats.allProducts", 0)),
      books: parseInt(_.get(tData, "stats.books", 0)) / parseInt(_.get(tData, "stats.allBooks", 0)),
      videos: parseInt(_.get(tData, "stats.videos", 0)) / parseInt(_.get(tData, "stats.allVideos", 0)),
      audios: parseInt(_.get(tData, "stats.audios", 0)) / parseInt(_.get(tData, "stats.allAudios", 0)),
    };
    _.each(ratios, (r, k) => (ratios[k] = Math.round(r * 100)));

    return (
      <>
        {_.isNaN(ratios.trainers) === false && (
          <>
            <ul className="resultlist">
              <li>
                <div className="circleChart" id="1" data-value={ratios.trainers} data-text={ratios.trainers + "%"}></div>
                <span>Trainers</span>
              </li>
              <li>
                <div className="canvas-wrap">
                  <div className="circleChart" id="2" data-value={ratios.products} data-text={ratios.products + "%"}></div>
                  <span id="procent2" classs="procent"></span>
                </div>
                <span>Products</span>
              </li>
              <li>
                <div className="canvas-wrap">
                  <div className="circleChart" id="3" data-value={ratios.books} data-text={ratios.books + "%"}></div>
                  <span id="procent3" classs="procent"></span>
                </div>
                <span>Books</span>
              </li>
              <li>
                <div className="canvas-wrap">
                  <div className="circleChart" id="4" data-value={ratios.audios} data-text={ratios.audios + "%"}></div>
                  <span id="procent4" classs="procent"></span>
                </div>
                <span>Audios</span>
              </li>
              <li>
                <div className="canvas-wrap">
                  <div className="circleChart" id="5" data-value={ratios.videos} data-text={ratios.videos + "%"}></div>
                  <span id="procent5" classs="procent"></span>
                </div>
                <span>Videos</span>
              </li>
            </ul>
          </>
        )}
      </>
    );
  };

  const showPageInfo = () => {
    let tcounts = filters.start + filters.limit;
    return (
      <>
        {_.min([_.get(tData, "pageInfo.total", 0), tData.data.length])}/{_.get(tData, "pageInfo.total", 0)} trainers
      </>
    );
  };

  const renderResource = (icon, type, resources, cnt) => {
    const res = _.filter(resources, { type: type });
    return (
      cnt < 4 && (
        <>
          {res.length > 0 && (
            <li>
              <div className="circleBox">
                <img className="img-fluid" src={`/assets/images/${icon}`} />
                <span className="desktopview">{res[0].price} USD</span>
                <span className="mobileview">{res[0].price}$</span>
              </div>
            </li>
          )}
        </>
      )
    );
  };

  const renderResources = (resources) => {
    const res = {
      pdf: "pdf.png",
      PPT: "doc-icon.png",
      audio: "audio-icon.png",
      video: "video.png",
      webinar: "webinar.png",
      scorm: "scome.png",
    };

    let resIdx = 0;

    return (
      <>
        {_.map(res, (icon, type) => {
          resIdx += _.findIndex(resources, { type: type }) > -1 ? 1 : 0;

          return renderResource(icon, type, resources, resIdx);
        })}

        {resIdx > 3 && (
          <li className="dotmore">
            <span></span>
            <span></span>
            <span></span>
          </li>
        )}
      </>
    );
  };

  const renderProductItem = (product) => {
    let trainer = viewTrainer;

    return (
      <div className="bioBodyInfolist">
        <a href={Utils.getTrainerURL(`products/${product.slug}`, trainer.slug)}>
          <ul>
            <li>{product.name}</li>
            <li>
              <img className="img-fluid iconImg" src={`${process.env.REACT_APP_API_URL}/uploads/products/${product.product_image}`} />
            </li>
            {renderResources(product.resources)}
          </ul>
        </a>
      </div>
    );
  };

  const showTrainerDetail = () => {
    let trainer = viewTrainer;

    let trainerbg = `${process.env.REACT_APP_API_URL}/uploads/profile/${trainer.profile_image}`;
    return (
      <div className="tab-content">
        <div className="tab-pane active trainerbg" style={{ backgroundImage: `url("${trainerbg}")` }} id="tab_a">
          <div className="tab-text-box">
            <img
              className="img-fluid progileImg"
              src={`${process.env.REACT_APP_API_URL}/uploads/base/${encodeURI(trainer.base_image)}`}
              alt={_.get(trainer, "firstname", "")}
            />
            <div className="bio-data-header">
              <h3>
                <a href={Utils.getTrainerURL("about", trainer.slug)}>
                  {_.get(trainer, "firstname", "")} {_.get(trainer, "lastname", "")}
                </a>
              </h3>
              <div className="bioInfo">
                Industry <span>{_.map(_.filter(_.get(trainer, "calibs", []), { pa_id: 1 }), (c) => c.pa_value).join(",")}</span>
              </div>
              <div className="bioInfo">
                Qualification <span>{_.map(_.filter(_.get(trainer, "calibs", []), { pa_id: 51 }), (c) => c.pa_value).join(",")}</span>
              </div>
              <div className="bioInfo">
                Year of Experience <span>{_.map(_.filter(_.get(trainer, "calibs", []), { pa_id: 68 }), (c) => c.pa_value).join(",")}</span>
              </div>
              <div className="bioInfo">
                Country <span>{_.map(_.filter(_.get(trainer, "calibs", []), { pa_id: 83 }), (c) => c.pa_value).join(",")}</span>
              </div>
            </div>
            <div className="profileRating">
              <p>Rating and Review</p>
              <StarRatings
                rating={trainer.rating.rating}
                starEmptyColor="#f9998a"
                starRatedColor="#dc3016"
                starHoverColor="#dc3016"
                starDimension="20px"
                starSpacing="2px"
              />
            </div>
            <div className="bio-data-body">
              {_.get(trainer, "products.products", []).map(renderProductItem)}
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12"></div>
                <div className="col-lg-4 col-md-4 col-12">
                  {isLoggedIn() && _.get(trainer, "sitesetting.preferred_trainers", 1) > 0 && (
                    <a href="#" className="action tocart primary btn btnBlue" onClick={markFav(trainer.user_id)}>
                      {!tData.favTrainers.includes(trainer.user_id) && <span>Mark Favourite</span>}
                      {tData.favTrainers.includes(trainer.user_id) && <span>Remove Favourite</span>}
                    </a>
                  )}
                </div>
                <div className="col-lg-4 col-md-4 col-12"> <a href={Utils.getTrainerURL("", trainer.slug)} className="action tocart primary btn btnBlue">
                  <span>View Profile</span>
                </a></div>

              </div>
              {/* <div className="text-right">
                <a href={Utils.getTrainerURL("", trainer.slug)} className="action tocart primary btn btnBlue">
                  <span>View Profile</span>
                </a> */}
              {/* <a href={Utils.getTrainerURL("products", trainer.slug)} target="_blank" className="action tocart primary btn btnBlue">
                  <span>View Products</span>
                </a> */}
              {/* {isLoggedIn() && _.get(trainer, "sitesetting.preferred_trainers", 1) > 0 && (
                  <a href="#" className="action tocart primary btn btnBlue" onClick={markFav(trainer.user_id)}>
                    {!tData.favTrainers.includes(trainer.user_id) && <span>Mark Favourite</span>}
                    {tData.favTrainers.includes(trainer.user_id) && <span>Remove Favourite</span>}
                  </a>
                )}
              </div> */}
            </div>
          </div>
        </div>
        <div className="alltrainers">
          <b>Total {trainer.products.total} products</b>
        </div>
      </div>
    );
  };

  const renderResults = () => (
    <>
      {_.get(tData, "data.length", 0) === 0 && (
        <div className="alert alert-warning text-center w-100">
          <div className="m-5">
            <i className="fa fa-info-circle"></i> No Matching profile found, please{" "}
            <a href={process.env.REACT_APP_PUBLIC_URL} className="text-dark">
              <u>search again</u>
            </a>
          </div>
        </div>
      )}
      {_.get(tData, "data.length", 0) > 0 && (
        <div className="flexWrapper">
          <div className="flexItem flex20">
            {
              <ul className="nav datascroll" onScroll={handleScroll}>
                {_.get(tData, "data", []).map((trainer, idx) => (
                  <li key={idx} className={trainer.user_id === viewTrainer.user_id ? "active" : ""}>
                    <span onClick={() => setViewTrainer(trainer)}>
                      <img
                        className="img-fluid"
                        src={`${process.env.REACT_APP_API_URL}/uploads/base/${trainer.base_image}`}
                        alt={_.get(trainer, "firstname", "")}
                      />
                      <span>
                        {_.get(trainer, "firstname", "")} {_.get(trainer, "lastname", "")}
                      </span>
                    </span>
                  </li>
                ))}
              </ul>
            }
            <div className="alltrainers alltrainerresult">
              <b>{showPageInfo()}</b>
            </div>
          </div>
          <div className="flexItem flex80">{_.get(viewTrainer, "user_id", 0) > 0 && showTrainerDetail()}</div>
        </div>
      )}
    </>
  );

  return (
    <>
      <section className="home-result-wrapper">{renderResultAnalysis()}</section>
      <div className="resultDisplay">
        <div id="resultDisplay">
          <h3 className="pb-5">Your Search Results </h3>
          {tData.loading && <Loader className="justify-content-center" />}
          {!tData.loading && !tData.success && (
            <div className="alert alert-danger text-center w-100">
              <div className="m-5">
                <i className="fa fa-times-circle"></i> {tData.message}
              </div>
            </div>
          )}
          {!tData.loading && tData.success && renderResults()}
        </div>
      </div>
    </>
  );
};

export default SearchResult;
