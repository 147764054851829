import React, { useEffect, useState, useContext } from "react";

import Utils from "./../Utils";

import _ from "lodash";
import UserMenu from "./UserMenu";
import UserContext from "./../contexts/UserContext";

const Header = (props) => {
  const { isLoggedIn, getUserData, isTrainer } = useContext(UserContext);
  const [cartCount, setCartCount] = useState(_.get(getUserData(), "cart", 0));

  console.log(isLoggedIn());

  const [loggedIn, setLoggedIn] = useState(isLoggedIn());

  const udata = getUserData();
  const $ = window.$;
  console.log(udata);
  useEffect(() => {
    $(".menu-toggle").on("click", function () {
      $(".sidenav").addClass("width100");
      $("body").addClass("menuopen");
    });
    $(".closemenu").on("click", function () {
      $(".sidenav").removeClass("width100");
      $("body").removeClass("menuopen");
    });

    if (loggedIn) {
      $(".profile_toggle").on("click", function () {
        $(".profile_menu").addClass("profile_width100");
      });
      $(".student_prof_cross").on("click", function () {
        $(".profile_menu").removeClass("profile_width100");
      });
    }
  }, []);

  return (
    <>
      <div className="sidenav">
        <div className="sidebarInner">
          <div className="overlay"></div>
          <img className="img-fluid closemenu" alt="Tverse" src="/assets/images/close-circle.png" />
          <form className="searchBOx mobileView">
            <input className="form-control" type="text" placeholder="How can I help you ?" />
          </form>
          <ul className="navList">
            <li>
              <a href={`${process.env.REACT_APP_PUBLIC_URL}/help-for-student`}>
                Help for Student <span>Start with a plan and finish with results.</span>
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_PUBLIC_URL}/contact-us`}>
                Contact <span>Get to know us, get to work with us.</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <header className="header fixed-top">
        <div className="container d-flex align-center mainheader">
          <a className="navbar-brand" href={`${process.env.REACT_APP_PUBLIC_URL}/`} title={props.sitesetting.company_name}>
            {!_.isEmpty(props.sitesetting.logo) && <img src={`${process.env.REACT_APP_API_URL}/uploads/logo/${props.sitesetting.logo}`} className="img-fluid sitelogo" alt="kstverse" />}
            {_.isEmpty(props.sitesetting.logo) && <>{props.sitesetting.company_name}</>}
          </a>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href="/my-cart">
                <img className="img-fluid shoppingIcon" src="/assets/images/cart-white.png" alt="autodidact" />
                {cartCount > 0 && (
                  <span className="badge bg-danger cartnumber">
                    {cartCount}
                  </span>
                )}
              </a>
            </li>
            {!loggedIn && (
              <li className="nav-item" data-toggle="modal" data-target="#loginModal" data-dismiss="modal">
                Log in
              </li>
            )}
            <li className="nav-item profile_toggle ml-2">
              <div className="Dropdown-Help">
                {isLoggedIn() && (
                  <>
                    <a href={Utils.getTrainerURL(`login`)} type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/uploads/${isTrainer() ? "base" : "student/base"}/${_.get(udata, "base_image", "")}`}
                        className="img-fluid"
                        title={`Logged in as ${udata.firstname} ${udata.lastname}`} alt="Tverse"
                      />
                    </a>
                    <ul className="dropdown-menu ksUserMenu" role="menu">
                      <UserMenu />
                    </ul>
                  </>
                )}
              </div>
            </li>

            {/* <li className="nav-item ">
                        <img className="img-fluid menu-toggle" src="/assets/images/toggle.png" alt="toggle-img" />
                    </li> */}
            <li className="nav-item ">
              <div className="Dropdown-Helps">
                <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
                  {/* <img className="img-fluid" src="/assets/images/toggle.png" alt="toggle-img" /> */}
                  <i className="fa fa-bars text-white"></i>
                </button>
                <ul className="dropdown-menu" role="menu">
                  <li className="dropdown-item HelpDropdown">
                    <a href={Utils.getTrainerURL(`help-for-student`)}>Help for Student</a>
                  </li>
                  <li className="dropdown-item HelpDropdown">
                    <a href={Utils.getTrainerURL(`contact-us`)}>Contact</a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        {/* {loggedIn && <div className="profile_menu from-right">
                <UserMenu />
            </div>} */}
      </header>
    </>
  );
};

export default Header;
