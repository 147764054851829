import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Utils from "../../Utils";
import UserContext from "../../contexts/UserContext";
import _ from "lodash";
import moment from "moment";
import { Loader } from "..";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

const SliderBlock = (props) => {
  const { getServerData, getUserData } = useContext(UserContext);
  const slug = Utils.hasSubdomain() ? Utils.subdomain() : getUserData().slug;
  const [tData, setTData] = useState([]);
  const [loading, setLoading] = useState(true);
  const defaultSlides = [{ slider_image: "slider-1.jpg" }, { slider_image: "slider-2.jpg" }, { slider_image: "slider-3.jpg" }];

  const fetchData = () => {
    getServerData(`trainer/blocks/${slug}?mode[]=slides`, true)
      .then((tData) => {
        setTData(tData);
        setLoading(false);
      })
      .catch((msg) => {
        setTData({ success: false, message: msg });
        setLoading(false);
      });
  };

  useEffect(fetchData, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <Row className="landingPageRow mt-4 mb-4">
          <div className="carouselslider">
            <MDBCarousel showIndicators fade>
              {(_.get(tData, "slides.data.length", 0) > 0 ? tData.slides.data : defaultSlides).map((slide, idx) => (
                <>
                  <a href={slide.cta_link}>
                    <MDBCarouselItem
                      className="w-100 d-block"
                      key={idx}
                      itemId={idx + 1}
                      src={process.env.REACT_APP_API_URL + "/uploads/slider/" + slide.slider_image}
                      alt="..."
                    />
                  </a>
                </>
              ))}
            </MDBCarousel>
          </div>
        </Row>
      )}
    </>
  );
};

export default SliderBlock;
