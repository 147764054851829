import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Utils from "../../Utils";
import UserContext from "../../contexts/UserContext";
import _ from "lodash";
import moment from "moment";
import { Loader } from "..";

const AwardBlock = (props) => {
  const { getServerData, getUserData } = useContext(UserContext);
  const slug = Utils.hasSubdomain() ? Utils.subdomain() : getUserData().slug;
  const [tData, setTData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    getServerData(`trainer/blocks/${slug}?mode[]=awards`, true)
      .then((tData) => {
        setTData(tData);
        setLoading(false);
      })
      .catch((msg) => {
        setTData({ success: false, message: msg });
        setLoading(false);
      });
  };

  useEffect(fetchData, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="">
          {tData.awards.map((a) => (
            <div className="awadText slideInUp wow mt-4 mb-4">
              <span className="awardYear">{a.year}</span> <span className="boldText">{a.award}</span> | <a href={a.url}>{a.organisation}</a>{" "}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default AwardBlock;
