import React, { useEffect, useState, useContext, cloneElement } from "react";
import { Container } from "react-bootstrap";
import { Header, HeaderTrainer, Footer, LoginModal, JoinAsStudent, JoinAsTrainer, JoinAsCorporate } from "./../components";
import Utils from "../Utils";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import UserContext from "./../contexts/UserContext";

const DefaultLayout = ({ children }) => {
  const { cid } = useParams();
  const { getServerData } = useContext(UserContext);
  const [sitesetting, setSitesetting] = useState({});

  const $ = window.$;

  const callbackfn = () => {
    if (Utils.hasSubdomain()) {
      //const id = 57;
      getServerData(`trainer/profiledata/${Utils.subdomain()}`).then((data) => {
        if (data.user_id > 0) {
          getServerData(`settings/trainer/${data.user_id}`).then((res) => {
            setSitesetting({ ...sitesetting, ...res.data, ...data });
          });
        }
      });
    } else {
      getServerData(`settings/trainer/0`).then((res) => {
        setSitesetting(res.data);
      });
    }
  };
  useEffect(callbackfn, []);
  useEffect(
    function () {
      if (sitesetting.company_name !== undefined && sitesetting.company_name !== "") {
        document.querySelectorAll("head title")[0].innerText = sitesetting.company_name;
      }
      if (sitesetting.favicon !== undefined && sitesetting.favicon !== "") {
        document.querySelectorAll("link[rel=icon]")[0].href = `${process.env.REACT_APP_API_URL}/uploads/favicon/${sitesetting.favicon}`;
      }
    },
    [sitesetting]
  );
  useEffect(() => {
    if (!_.isUndefined(cid) && parseInt(cid) > 0) {
      $("#signUpTrainer").modal('show');
    }

  }, []);

  const hasSubdomain = Utils.hasSubdomain();
  const location = useLocation();
  return (
    <Container fluid className="h-100 p-0">
      {!hasSubdomain && sitesetting && <Header sitesetting={sitesetting} />}
      {hasSubdomain && parseInt(_.get(sitesetting, "id", 0)) > 0 && location.pathname !== "/readls" && <HeaderTrainer sitesetting={sitesetting} />}
      {cloneElement(children, { sitesetting: sitesetting })}
      <LoginModal />
      <JoinAsStudent />
      <JoinAsTrainer cid={cid} />
      <JoinAsCorporate />
      {sitesetting && <Footer sitesetting={sitesetting} />}
    </Container>
  );
};

export default DefaultLayout;
