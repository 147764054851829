import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import Utils from "../../Utils";
import StarRatings from "react-star-ratings";
import UserContext from "./../../contexts/UserContext";
import { Helmet } from "react-helmet";
import { TwitterIcon, FacebookIcon, LinkedinIcon } from "react-share";
import Loader from "../Loader";

const AboutMeBlock = (props) => {
  const settings = _.get(props, "settings", {});
  const { socialPlatforms } = Utils;
  const [data, setData] = useState({ about: false, social: false, rating: false, loading: true });
  const [starLoading, setStarLoading] = useState(false);
  const { getUserData, isLoggedIn, isTrainer, getServerData, setServerData } = useContext(UserContext);
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());

  const fetchProfile = () => {
    // setData({ ...data, loading: true });
    getServerData(`trainer/profile/${Utils.subdomain()}?mode=[social,rating]`, true).then(res => setData({ ...res, loading: false }));
  };

  useEffect(fetchProfile, []);

  const setTrainerRating = (rated) => {
    setStarLoading(true);
    let ratingData = new FormData();
    ratingData.append("trainer_id", data.about.user_id);
    ratingData.append("rating", rated);
    setServerData(`trainer/setRating`, ratingData, "post")
      .then((res) => {
        console.log(res);
        setStarLoading(false);
        setData({ ...data, rating: res.success ? res.rating : data.rating });
      })
      .catch((msg) => {
        setStarLoading(false);
        // do nothing
      });
  };

  const trainerUrl = Utils.getTrainerURL("", data.about.slug);
  const trainerName = data.about.firstname + " " + data.about.lastname;

  return (
    <>
      {data.loading === false && <div>
        <div className="profileHeading teacherheading mb-4">
          {/* <h1 className="headingtext wow zoomIn">{data.firstname} {data.lastname}</h1> */}
          <h1 className="headingtext d-flex align-center">{trainerName}</h1>
          {settings.features.indexOf("rating") > -1 && (
            <div className="profileDetailRating">
              {!starLoading && (
                <div>
                  <StarRatings
                    rating={data.rating.rating}
                    starEmptyColor="#dddddd"
                    starRatedColor="#f3ac1b"
                    starHoverColor="#bfa700"
                    starDimension="20px"
                    starSpacing="2px"
                    changeRating={isLoggedIn() ? setTrainerRating : false}
                  />
                  <div className="mx-2 my-1">{data.rating.ratings} ratings</div>
                </div>
              )}
            </div>
          )}

          <p className="joindetails mt-3">
            Joined {Utils.shortDate(data.about.created_at)}
            {/* &nbsp;&nbsp;&nbsp;&nbsp; Students {props.total.students} &nbsp;&nbsp;&nbsp;&nbsp; Courses{" "}
                {props.total.courses} */}
          </p>
          {settings.features.indexOf("social") > -1 && (
            <div className="profileFollowList">
              <h5>Follow {data.about.firstname}</h5>
              <ul>
                {socialPlatforms.map(
                  (sp) =>
                    !_.isEmpty(_.get(data, `social.${sp}`, "")) && (
                      <li>
                        <a target="_blank" href={data.social[sp]}>
                          <i className={`fab fa-${sp}`}></i>
                        </a>
                      </li>
                    )
                )}
              </ul>
            </div>
          )}
          {settings.features.indexOf("share") > -1 && (
            <ul className="profile-socail-icon">
              <Helmet>
                <link rel="canonical" href={`${trainerUrl}professional-profile/about`} />
                <meta property="og:title" content={`${trainerName}`} />
                <meta property="og:description" content={`${trainerName}`} />
                <meta property="og:image" content={`${data.about.base_image}`} />
                <meta property="og:url" content={`${trainerUrl}professional-profile/about`} />
                <meta property="og:type" content="website" />
              </Helmet>
              <li>
                <a href={`https://twitter.com/intent/tweet?url=${trainerUrl}&text=${trainerName}`}>
                  <TwitterIcon size={32} round={true} />
                </a>
              </li>
              <li>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${trainerUrl}&quote=${trainerName}&imageURL=${data.about.base_image}`}>
                  <FacebookIcon size={32} round={true} />
                </a>
              </li>
              <li>
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${trainerUrl}professional-profile/about&title=${trainerName}&summary=${trainerName}&source=TVerse&mini=true&ro=true&imageUrl=${data.about.base_image}`}
                >
                  <LinkedinIcon size={32} round={true} />
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>}
    </>
  );
};

export default AboutMeBlock;
