import React, { useState, useContext, useEffect } from "react";
import { Container, Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import UserContext from "./../contexts/UserContext";
import _ from "lodash";
import moment from "moment";
import Utils from "./../Utils";

const MyPages = (props) => {
  const [mode, setMode] = useState(0); //1: add, 2: edit
  const [rec, setRec] = useState({});
  const [pages, setPages] = useState({ loading: false, data: [], message: "", error: false });
  const { getServerData, setServerData, getUserData } = useContext(UserContext);
  const fetchMyPages = () => {
    getServerData("trainer/my-pages")
      .then((res) => {
        setPages({ ...pages, loading: false, data: res });
      })
      .catch((err) => {
        setPages({ ...pages, loading: false, data: [], error: true, message: err.message });
      });
  };

  useEffect(fetchMyPages, []);

  useEffect(() => {
    if (mode > 0) {
      window.setTimeout(() => window.$("#pagetitleele").focus(), 500);
    }
  }, [mode]);

  const savePage = (e) => {
    let formData = new FormData(e.currentTarget);
    if (mode === 2) {
      formData.set("id", rec.id);
    }
    e.preventDefault();
    setPages({ ...pages, loading: true, message: "Saving page..." });
    setServerData("trainer/my-pages", formData)
      .then((res) => {
        setMode(0); //== close the dialog
        fetchMyPages();
      })
      .catch((err) => {
        setPages({ ...pages, loading: false });
      });
  };

  const showForm = () => {
    return (
      <Modal show={true} onHide={() => setMode(0)}>
        <Modal.Header closeButton>
          <Modal.Title>{["", "Add", "Edit"][mode]} Page</Modal.Title>
        </Modal.Header>
        <Form onSubmit={savePage}>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Label>Page Name</Form.Label>
                <Form.Control
                  id="pagetitleele"
                  name="title"
                  placeholder="Enter page name"
                  defaultValue={mode === 2 ? _.get(rec, "title", "") : ""}
                  required
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="info">
              Save
            </Button>
            <Button variant="secondary" onClick={() => setMode(0)}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  const togglePage = (pid, checked) => {
    if (window.confirm(`You are going to ${checked ? "ENABLE" : "DISABLE"} this page\n\n Continue?`)) {
      setPages({ ...pages, loading: true, message: `${checked ? "Enabling" : "Disabling"} the page...` });
      setServerData("trainer/my-pages", `id=${pid}&is_active=${checked ? 1 : 0}`)
        .then((res) => {
          if (res.success) {
            let data = pages.data.map((p, idx) => ({
              ...p,
              is_active: p.id === pid ? checked : p.is_active,
            }));
            setPages({ ...pages, loading: false, data: data });
          } else {
            setPages({ ...pages, loading: false });
          }
        })
        .catch((e) => setPages({ ...pages, loading: false }));
    }
  };

  const switchWeight = (p1, p2) => {
    setServerData("trainer/my-pages-sortify", `p1id=${p1.id}&p1w=${p2.weight}&p2id=${p2.id}&p2w=${p1.weight}`)
      .then((res) => {
        if (res.success) {
          fetchMyPages();
        } else {
          setPages({ ...pages, loading: false });
        }
      })
      .catch((e) => setPages({ ...pages, loading: false }));
  };

  const sortActions = (p, idx) => {
    return (
      <>
        {idx < pages.data.length - 1 && (
          <i
            className="clickable fas fa-2x fa-arrow-alt-circle-down mx-1 text-info"
            title="display down"
            onClick={() => switchWeight(p, pages.data[idx + 1])}
          />
        )}
        {idx === pages.data.length - 1 && <i className="fas fa-2x fa-arrow-alt-circle-down mx-1" style={{ color: "#ddd" }} />}
        {idx > 0 && (
          <i
            className="clickable fas fa-2x fa-arrow-alt-circle-up mx-1 text-info"
            title="display up"
            onClick={() => switchWeight(p, pages.data[idx - 1])}
          />
        )}
        {idx === 0 && <i className="fas fa-2x fa-arrow-alt-circle-up mx-1" style={{ color: "#ddd" }} />}
      </>
    );
  };

  return (
    <Container fluid className="h-100 p-0">
      <div className="profile-wrapper">
        <div className="container">
          <div className="card-header ui-sortable-handle">
            <h3 className="card-title">My Pages </h3>
            <span className="float-right">
              <button className="btn btn-primary-outline bg-primary text-light btn-sm mt-1" onClick={() => setMode(1)}>
                Add Page <i className="fas fa-plus"></i>
              </button>
            </span>
          </div>
          {pages.loading && (
            <div className="bg-light p-5 text-center">
              {pages.message} <Spinner animation="border" />
            </div>
          )}
          {!pages.loading && (
            <>
              {pages.data.length === 0 && (
                <div className="bg-light p-5 text-center">
                  <div className="p-5">
                    Yet to add pages for your profile! Start adding new page
                    <button className="btn btn-sm btn-success ml-2" onClick={() => setMode(1)}>
                      <i className="fas fa-plus" />
                    </button>
                  </div>
                </div>
              )}
              {pages.data.length > 0 && (
                <div className="table-responsive">
                  <table className="table table-striped borderless">
                    <thead>
                      <tr>
                        <th width="50">Sl.</th>
                        <th>Page</th>
                        <th width="120">Sequence</th>
                        <th width="120">Blocks</th>
                        <th width="170" className="text-center">
                          Updated On
                        </th>
                        <th width="180" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pages.data.map((p, idx) => (
                        <tr key={p.id}>
                          <td>{idx + 1}</td>
                          <td>
                            <b>{p.title}</b>
                          </td>
                          <td>{sortActions(p, idx)}</td>

                          <td>{p.blocks}</td>
                          <td>{moment(_.isNull(p.updated_at) ? p.created_at : p.updated_at).format("DD-MMM-YY HH:mm a")}</td>
                          <td className="d-flex flex-row-reverse">
                            <button
                              className="btn btn-outline-dark p-1 px-2 mx-1"
                              onClick={() => {
                                setMode(2);
                                setRec(p);
                              }}
                              style={{ borderRadius: "20px" }}
                              title="Edit Page"
                            >
                              <i className="clickable fas fa-pen" />
                            </button>

                            <span
                              className={`clickable fas fa-2x mx-1 ${p.is_active ? "fa-check-circle text-success" : "fa-times-circle text-secondary"}`}
                              title={`${p.is_active ? "Disable" : "Enable"} this page`}
                              onClick={() => togglePage(p.id, !p.is_active)}
                            />

                            <a
                              className="btn btn-outline-dark p-1 px-2 mx-1"
                              href={`/my-blocks/${p.id}`}
                              style={{ borderRadius: "20px" }}
                              title="Manage Blocks"
                            >
                              <i className="fas fa-cog" />
                            </a>

                            <a
                              className="btn btn-outline-primary p-1 px-2 mx-1"
                              target="_blank"
                              href={Utils.getTrainerURL(p.slug, getUserData().slug)}
                              style={{ borderRadius: "20px" }}
                              title="View"
                            >
                              <i className="fas fa-eye" />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {(mode === 1 || mode === 2) && showForm()}
    </Container>
  );
};

export default MyPages;
