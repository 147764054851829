import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Modal, Carousel } from "react-bootstrap";
import Utils from "../../Utils";
import UserContext from "../../contexts/UserContext";
import _ from "lodash";
import moment from "moment";
import { Loader } from "..";

const TestimonialBlock = (props) => {
  const settings = _.get(props, "settings", {});
  const { getServerData, getUserData } = useContext(UserContext);
  const slug = Utils.hasSubdomain() ? Utils.subdomain() : getUserData().slug;
  const [tData, setTData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    getServerData(`trainer/blocks/${slug}?mode[]=testimonials&limit=${_.get(settings, "limit", 999999)}`, true)
      .then((tData) => {
        setTData(tData);
        setLoading(false);
      })
      .catch((msg) => {
        setTData({ success: false, message: msg });
        setLoading(false);
      });
  };

  useEffect(fetchData, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <Row className="landingPageRow mt-4 mb-4">
          <div className="testimonialIndicator">
            <Carousel showIndicators controls={false} fade>
              {tData.testimonials.map((testimonial, idx) => (
                <Carousel.Item className="px-3" key={idx}>
                  <div className="font-weight-bold">
                    <i class="fa fa-quote-left" aria-hidden="true"></i> {testimonial.author}
                  </div>
                  <hr className="line" />
                  <div dangerouslySetInnerHTML={{ __html: testimonial.testimonial }}></div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </Row>
      )}
    </>
  );
};

export default TestimonialBlock;
