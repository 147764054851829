import React from "react";
import _ from "lodash";
import { TeacherSubscribe } from "../teacher";
const SubscribeBlock = (props) => {
  return (
    <>
      <div className="mt-4 mb-4">
        <p className="text-left ContactSubscribe mt-4">
          Subscribe to Our {_.get(props, "sitesetting.company_name", process.env.REACT_APP_CONTACT_NAME)} Newsletter
        </p>
        <TeacherSubscribe type="inLine" />
      </div>
    </>
  );
};

export default SubscribeBlock;
