import { useEffect, useContext, useState, useRef } from "react";
import { Form, Alert, Spinner, Row, Col, Button, Modal, Tabs, Tab } from "react-bootstrap";
import UserContext from "../../contexts/UserContext";
import TextEditor from "../TextEditor";
import _ from "lodash";
import Utils from "../../Utils";
import Loader from "../Loader";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ProductForm = (props) => {
  const [mode, setMode] = useState("Add");

  const [myproduct, setMyproduct] = useState({ is_course: 0, is_bundle: 0 });
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(_.get(props, "id", false));
  const [response, setResponse] = useState({ success: false, message: "" });
  const { getServerData, setServerData } = useContext(UserContext);
  const frmRef = useRef("productform");
  const MySwal = withReactContent(Swal);

  const onContentChange = (fld) => (value) => {
    let c = { ...myproduct };
    c[fld] = value;
    setMyproduct(c);
  };

  const setProductType = (ptype) => {
    setMyproduct({ ...myproduct, is_bundle: ptype === "bundle" ? 1 : 0, is_course: ptype === "course" ? 1 : 0 });
  };

  const getProductType = () => {
    return myproduct.is_bundle === 1 ? "bundle" : myproduct.is_course === 1 ? "course" : "product";
  };

  useEffect(() => {
    if (_.get(props, "id", false)) {
      setLoading(true);
      getServerData("trainer/my-products?where[id]=" + props.id)
        .then(setMyproduct)
        .then(() => setMode("Update"))
        .then(() => setLoading(false))
        .catch((err) => console.log(err));
    }
  }, []);
  useEffect(window.scrollEffect, []);

  useEffect(() => {
    window.setTimeout(() => setResponse({ message: "" }), 5000);
  }, [response]);

  const onSave = (e) => {
    const frm = frmRef.current;
    e.preventDefault();
    frm.classList.add("was-validated");
    /*if(((_.get(myproduct,'short_description','')) === '')){
      const element = document.querySelector('.short_description');
      element.style.display = 'block';
      return false;
    }
    if(((_.get(myproduct,'description','')) === '')){
      const element = document.querySelector('.description');
      element.style.display = 'block';
      return false;
    }*/
    if (frm.checkValidity() === false) {
      return false;
    }
    let frmdata = new FormData(frm);

    // if (myproduct.is_bundle === 1 && window.$(frm.productids).find(":selected").length < 2) {
    //   window.alert("Please select atleast 2 products to build a bundle");
    //   return false;
    // }


    if (myproduct.is_bundle === 1 && window.$(frm.productids).find(":selected").length < 2) {
      MySwal.fire({
        title: 'Please select atleast 2 products to build a bundle',
        icon: 'info',
        confirmButtonColor: '#0f79aa',
        confirmButtonText: 'Ok',
        closeOnClickOutside: false
      })
      return false;
    };

    frmdata.append("is_course", myproduct.is_course === 1 ? 1 : 0);
    frmdata.append("is_bundle", myproduct.is_bundle === 1 ? 1 : 0);

    frmdata.append("old_products_image", _.get(myproduct, "product_image", ""));
    frmdata.append("short_description", _.get(myproduct, "short_description", ""));
    frmdata.append("description", _.get(myproduct, "description", ""));
    setSaving(true);
    setServerData("trainer/my-products", frmdata).then((res) => {
      setSaving(false);
      setResponse(res);
      props.onSave();
      props.onClose();
    });
  };

  const photoUploader = (fld, title) => {
    return (
      <>
        <Form.Label>{title}</Form.Label>
        <Form.Control type="file" size="lg" name={fld} accept=".jpeg,.png,.jpg,JPEG;" />
        {_.get(myproduct, "product_image", "") !== "" && (
          <img className="thumbnail mt-3" src={`${process.env.REACT_APP_API_URL}/uploads/products/${myproduct.product_image}`} />
        )}
      </>
    );
  };

  const renderForm = () => (
    <div className="p-3">
      {mode === "Add" && (
        <Tabs defaultActiveKey={getProductType(myproduct)} className="mb-3" onSelect={setProductType}>
          <Tab
            eventKey="product"
            tabClassName={myproduct.is_course === 0 && myproduct.is_bundle === 0 ? "bg-primary text-light px-5" : "px-5"}
            title="Add Product"
          ></Tab>
          <Tab
            eventKey="course"
            tabClassName={myproduct.is_course === 1 && myproduct.is_bundle === 0 ? "bg-primary text-light px-5 mx-1" : "px-5 mx-1"}
            title="Add Course"
          ></Tab>
          <Tab eventKey="bundle" tabClassName={myproduct.is_bundle === 1 ? "bg-primary text-light px-5" : "px-5"} title="Add Bundle"></Tab>
        </Tabs>
      )}

      <Form noValidate ref={frmRef} id="product-form" onSubmit={onSave} method="post" className="form contact alertdesign needs-validation">
        <Form.Control type="hidden" name="id" defaultValue={_.get(myproduct, "id", "")} />
        <Form.Control type="hidden" name="mid" defaultValue={_.get(myproduct, "moodle_id", "")} />
        <Form.Control type="hidden" name="old_product_image" defaultValue={_.get(myproduct, "product_image", "")} />
        <Row>
          <Col md={12} className="mt-3">
            <Form.Label>{_.capitalize(getProductType())} Title * : </Form.Label>
            <Form.Control type="text" name="name" placeholder="Enter Title" defaultValue={_.get(myproduct, "name", "")} required />
            <div className="invalid-feedback">Title is required!</div>
          </Col>
        </Row>
        {mode === "Update" && (
          <Row>
            <Form.Control type="hidden" name="sku" defaultValue={_.get(myproduct, "sku", "")} />

            <Col md={12} className="mt-4">
              <Form.Label>Slug: </Form.Label>
              <Form.Control type="text" name="slug" placeholder="Enter product slug" defaultValue={_.get(myproduct, "slug", "")} />
            </Col>
          </Row>
        )}

        <Row>
          <Col md={3} className="mt-3">
            {photoUploader("product_image", "Upload image:")}
          </Col>
          <Col md={9} className="mt-3">
            <Form.Label>Short Description * : </Form.Label>
            <TextEditor value={_.get(myproduct, "short_description", "")} onChange={onContentChange("short_description")} />
            <div className="short_description invalid-feedback">Short description is required!</div>
          </Col>
        </Row>

        {myproduct.is_bundle === 0 && (
          <>
            <Row>
              <Col md={12} className="mt-3">
                <Form.Label>Description * : </Form.Label>
                <TextEditor value={_.get(myproduct, "description", "")} onChange={onContentChange("description")} />
                <div className="description invalid-feedback">Description is required!</div>
              </Col>
            </Row>

            {myproduct.is_course === 1 && <Row>
              <Col md={4} className="mt-3">
                <Form.Label>Level * : </Form.Label>
                <Form.Control as="select" name="level" defaultValue={_.get(myproduct, `level`, "")} required>
                  <option value=""> - Select Level - </option>
                  {Utils.productLevel.map((v) => (
                    <option key={v} selected={v == _.get(myproduct, `level`, "")} value={v}>
                      {v}
                    </option>
                  ))}
                </Form.Control>
                <div className="invalid-feedback">Level is required!</div>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Label>language * : </Form.Label>
                <Form.Control as="select" name="language" defaultValue={_.get(myproduct, `language`, "")} required>
                  <option value=""> - Select Language - </option>
                  {Utils.country.map((v) => (
                    <option key={v} selected={v == _.get(myproduct, `language`, "")} value={v}>
                      {v}
                    </option>
                  ))}
                </Form.Control>
                <div className="invalid-feedback">Language is required!</div>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Label>Duration (In Hours): </Form.Label>
                <Form.Control type="number" name="duration" placeholder="Enter duration" defaultValue={_.get(myproduct, "duration", "")} />
              </Col>
            </Row>}
          </>
        )}

        {myproduct.is_bundle === 1 && (
          <>
            <Form.Label>Select Bundle Products * : </Form.Label>
            <Form.Control as="select" name="productids" multiple required>
              {_.map(props.allProducts, (p) => (
                <option
                  value={p.id}
                  selected={_.get(myproduct, "productids", "")
                    .split(",")
                    .includes(p.id + "")}
                >
                  {p.name}
                </option>
              ))}
            </Form.Control>
          </>
        )}
        <Row>
          <Col md={12} className="mt-3 text-right">
            {saving && (
              <>
                Saving.. <Spinner animation="border" />
              </>
            )}
            {!saving && response.message === "" && (
              <Button type="submit" className="profile-save">
                Save
              </Button>
            )}
            {!saving && response.message !== "" && (
              <Alert variant={response.success ? "info" : "danger"} className="p-3 mt-2 text-center">
                {response.message}
              </Alert>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );

  const renderModal = () => (
    <Modal show={true} size="xl" onHide={_.get(props, "onClose", "")}>
      <Modal.Header closeButton>
        <Modal.Title>{mode} Product</Modal.Title>
      </Modal.Header>

      <Modal.Body>{renderForm()}</Modal.Body>
    </Modal>
  );

  return (
    <>
      {loading && <Loader />}
      {!loading && props.type !== "modal" && renderForm()}
      {!loading && props.type === "modal" && renderModal()}
    </>
  );
};

export default ProductForm;
