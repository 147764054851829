import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Loader } from "..";

const YoutubeBlock = (props) => {
  const ychannel = _.get(props, "settings.channel-id", "");
  let limit = _.get(props, "settings.limit", 999999);
  if (_.isEmpty(limit)) {
    limit = 9999999;
  }
  const [loading, setLoading] = useState(true);
  const [ydata, setYdata] = useState([]);
  const fetchYoutube = () => {
    // const ytKey = "AIzaSyDhNrgFOcQSr719GnpjYfRWJAYJqiwySUI";

    fetch(
      `https://www.googleapis.com/youtube/v3/search?order=date&part=snippet&channelId=${ychannel}&maxResults=${limit}&key=${process.env.REACT_APP_YOUTUBE_APIKEY}`
    )
      .then((response) => response.json())
      .then((yd) => {
        setYdata(_.get(yd, "items", []));
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(fetchYoutube, []);

  const youtubeItem = ({ id, snippet }) => {
    return (
      <div key={id} className="col-sm-6 col-md-4">
        <div className="knowledgeBox slideInUp wow ">
          <div className="knowledgeImg">
            <iframe
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${id.videoId}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="knowledgeTitle">{snippet.title}</div>
          <div className="knowledgeBody">Publish Date {moment(snippet.publishedAt).format("YYYY-MM-DD")}</div>
          <div className="knowledgeFooter clearfix">
            <div className="FText">{snippet.channelTitle}</div>
            {/* <ul><li><a href=""><img src="/assets/images/eyes.png" alt="ad eyes" /></a></li><li className='ml-2'><a href=""><img src="/assets/images/share-icon.png" alt="ad share" /></a></li></ul>*/}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-4 mb-4">
      {loading && <Loader />}
      {!loading && (
        <>
          {ychannel && (
            <>
              {ydata.length > 0 && (
                <>
                  <div className="freeResouces lineANimation ">Free Resources</div>
                  <div className="row">{ydata.length > 0 && (ydata.length > limit ? _.slice(ydata, 0, limit) : ydata).map(youtubeItem)}</div>
                </>
              )}
              {ydata.length === 0 && (
                <>
                  <div className="freeResouces lineANimation ">Free Resources</div>
                  <div className="mb-3">
                    <b>Coming Soon!</b>
                  </div>
                  {/* <div className="col-sm-6 col-md-4">Coming Soon</div> */}
                </>
              )}
            </>
          )}

          {!ychannel && (
            <>
              <div className="freeResouces lineANimation slideInUp wow ">Free Resources</div>
              <div className="mb-3">
                <b>Coming Soon!</b>
              </div>
              {/* <div className="col-sm-6 col-md-4">Coming Soon</div> */}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default YoutubeBlock;
