import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Utils from "../../Utils";
import UserContext from "../../contexts/UserContext";
import _ from "lodash";
import moment from "moment";
import { Loader } from "..";

const BlogsBlock = (props) => {
  const settings = _.get(props, "settings", {});
  const { getServerData, getUserData } = useContext(UserContext);
  const slug = Utils.hasSubdomain() ? Utils.subdomain() : getUserData().slug;
  const [tData, setTData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    getServerData(`trainer/blocks/${slug}?mode[]=blogs&limit=${_.get(settings, "limit", 999999)}`, true)
      .then((tData) => {
        setTData(tData);
        setLoading(false);
      })
      .catch((msg) => {
        setTData({ success: false, message: msg });
        setLoading(false);
      });
  };

  useEffect(fetchData, []);

  const blogItem = (blog) => {
    return (
      <div className="col-md-6 col-lg-4" key={blog.id}>
        <a href={`/trainers/${props.slug}/blogs/${blog.slug}`} rel="noreferrer" target="_blank">
          <div className="knowledgeBox slideInUp wow ">
            <div className="knowledgeImg">
              <img className="img-fluid" src={`${process.env.REACT_APP_API_URL}/uploads/blog/${blog.blog_image}`} alt="ad blog" />
            </div>
            <div className="knowledgeTitle">{blog.name}</div>
            <div className="knowledgeBody">Publish Date: {Utils.shortDate(blog.created_at)}</div>
            <div className="knowledgeFooter clearfix">
              <div className="FText">Blog</div>
              <ul>
                <li>
                  <a href={`/trainers/${props.slug}/blogs/${blog.slug}`}>
                    <img src="/assets/images/eyes.png" alt="ad eyes" />
                  </a>
                </li>
                <li></li>
              </ul>
            </div>
          </div>
        </a>
      </div>
    );
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="freeResouces lineANimation slideInUp wow mt-5">Blogs</div>
          <div className="row">{tData.blogs.map(blogItem)}</div>
        </>
      )}
    </>
  );
};

export default BlogsBlock;
