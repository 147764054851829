import React, { useState, useEffect, useContext } from "react";
import Utils from "../../Utils";
import { useLocation } from "react-router-dom";
import _ from "lodash";

const TeacherNav = (props) => {
  const { pathname } = useLocation();

  const setPageName = (pname) => (e) => {
    props.onPageChange(pname);
    e.preventDefault();
    return false;
  };

  const menuItem = (label, path) => (
    <li key={path} className={`nav-item ${props.page && path.indexOf(props.page) > -1 ? "active" : ""}`}>
      <div className="navLinksEdit">
        <div className="editTag">
          <a className="nav-link" href={path === "home" ? "/" : `/${path}`}>
            {label}
          </a>
        </div>
      </div>
    </li>
  );

  return <ul className="navbar-nav mt-2 mt-lg-0">{_.map(props.pagelinks, (page, k) => menuItem(page.title, page.slug))}</ul>;
};

export default TeacherNav;
