import Login from "./Login";
import About from "./About";
import Home from "./Home";
import ADStudio from "./ADStudio";
import ADTrainer from "./ADTrainer";
import MyProfile from "./MyProfile";
import TeacherLanding from "./TeacherLanding";
import ManageProducts from "./ManageProducts";
import ProductList from "./ProductList";
import ProductDetails from "./ProductDetails";
import SearchResult from "./SearchResult";
import ADStudent from "./ADStudent";
import ContactUs from "./ContactUs";
import StudentProfile from "./StudentProfile";
import MyCart from "./MyCart";
import StudentProfileView from "./StudentProfileView";
import MyBlog from "./MyBlog";
import MyTestimonial from "./MyTestimonial";
import BlogList from "./BlogList";
import PaymentSuccess from "./PaymentSuccess";
import PaypalProcess from "./PaypalProcess";
import CookiePolicy from "./CookiePolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import TermConditions from "./TermConditions";
import MyOrder from "./MyOrder";
import MySales from "./MySales";
import MyStudent from "./MyStudent";
import BlogDetails from "./BlogDetails";
import ResetPassword from "./ResetPassword";
import PreferredTrainers from "./PreferredTrainers";
import PreferredProducts from "./PreferredProducts";
import ReadLocalStorage from "./ReadLocalStorage";
import ChangePassword from "./ChangePassword";
import MyCorporateGroup from "./MyCorporateGroup";
import CorporateGroupDetail from "./CorporateGroupDetail";
import LandingPage from "./LandingPage";
import ManageCoupons from "./ManageCoupons";
import LoginPage from "./LoginPage";
import SiteSettings from "./SiteSettings";
import Checkout from "./Checkout";
import MyPages from "./MyPages";
import MyBlocks from "./MyBlocks";
import TrainerPage from "./TrainerPage";
import Blogs from "./Blogs";
import BlogsDetails from "./BlogsDetails";

export default {
  Login,
  About,
  Home,
  ADStudio,
  ADTrainer,
  MyProfile,
  ManageProducts,
  ProductDetails,
  SearchResult,
  TeacherLanding,
  ProductList,
  ContactUs,
  ADStudent,
  StudentProfile,
  MyCart,
  StudentProfileView,
  MyBlog,
  BlogList,
  PaymentSuccess,
  CookiePolicy,
  PrivacyPolicy,
  TermConditions,
  MyOrder,
  MySales,
  MyStudent,
  BlogDetails,
  ResetPassword,
  PreferredTrainers,
  PreferredProducts,
  ReadLocalStorage,
  ChangePassword,
  MyCorporateGroup,
  CorporateGroupDetail,
  LandingPage,
  ManageCoupons,
  LoginPage,
  SiteSettings,
  Checkout,
  PaypalProcess,
  MyPages,
  MyBlocks,
  TrainerPage,
  MyTestimonial,
  Blogs,
  BlogsDetails,
};
