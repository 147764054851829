import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import _ from "lodash";
import DataTableGrid from "../DataTableGrid";
import ResourceForm from "./ResourceForm";
import axios from "axios";
import Utils from "../../Utils";
import moment from "moment";
import UserContext from "../../contexts/UserContext";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ProductResources = (props) => {
  const { apiHeaders, getServerData } = useContext(UserContext);
  const [showmc, setShowmc] = useState({ show: false, row: null, type: null });
  const [list, setList] = useState({ loading: false, error: false, pageInfo: {}, data: [] });
  const [showForm, setShowForm] = useState({ id: false, mode: 0 }); // 0=do not show, 1=add, 2=edit
  const MySwal = withReactContent(Swal);

  const listColumns = ["id", "name", "price", "type", "created_at"];
  const columns = listColumns.map((v) => ({
    name: v.toUpperCase(),
    selector: (row) => row[v],
    format: (row) => {
      if (v === "created_at") {
        return moment(row[v]).format("DD MMM YYYY");
      }
      else {
        return row[v];
      }
    },
    sortable: true,
  }));

  columns.push({
    name: "Action",
    cell: (row) => (
      <>
        <Button size="sm" variant="light" className="mr-1" onClick={() => setShowForm({ id: row.id, mode: 2 })}>
          <i className="fa fa-edit" />
        </Button>
        <Button size="sm" variant="light" className="mr-1" onClick={deleteRecord(row.id)}>
          <i className="fa fa-trash text-danger" />
        </Button>
      </>
    ),
    sortable: false,
  });

  const deleteRecord = (id) => (e) => {
    MySwal.fire({
      title: 'You are going to delete record?',
      text: "Are you sure to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0f79aa',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success',
          axios.delete(Utils.apiUrl(`trainer/product-resources/${id}`), apiHeaders()).then((res) => {
            fetchList();
          })
        )
      }
    })
  };

  const fetchList = () => {
    setList({ ...list, loading: true });
    let params = `fname=product_id&fvalue=${props.id}`;
    getServerData(`trainer/product-resources?${params}`, true).then((res) => {
      if (res.success) {
        setList({ ...list, loading: false, error: false, pageInfo: res.pageInfo, data: res.data.map((v) => _.pick(v, listColumns)) });
      } else {
        setList({ ...list, loading: false, error: res.message, pageInfo: {}, data: [] });
      }
    });
  };
  useEffect(window.scrollEffect, []);
  useEffect(fetchList, []);

  const renderButton = () => (
    <div className="card-header ui-sortable-handle mb-0">
      <h3 className="card-title">Resource List #</h3>
      <span className="btn float-right">
        <button className="btn btn-success btn-sm" onClick={() => setShowForm({ mode: 1, id: false })}>
          Add Product Resource <i className="fas fa-plus"></i>
        </button>
      </span>
    </div>
  );

  return (
    <Modal show={true} size="xl" onHide={_.get(props, "onClose", "")}>
      <Modal.Header closeButton>
        <Modal.Title>Product Resources for {props.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {renderButton()}
        <DataTableGrid columns={columns} data={list.data} />
      </Modal.Body>
      {showForm.mode > 0 && (
        <ResourceForm type="modal" id={showForm.id} product_id={props.id} onClose={() => setShowForm({ ...showForm, mode: 0 })} onSave={fetchList} />
      )}
    </Modal>
  );
};

export default ProductResources;
