import { useEffect, useContext, useState } from "react";
import { Form, Alert, Spinner, Row, Col, Button, Modal } from "react-bootstrap";
import UserContext from "./../../contexts/UserContext";
import TextEditor from "../TextEditor";
import _ from "lodash";
import Utils from "./../../Utils";

const BlogForm = (props) => {
  const [mode, setMode] = useState("Add");

  const [myblog, setMyBlog] = useState({});
  const [saving, setSaving] = useState(false);
  const [response, setResponse] = useState({ success: false, message: "" });
  const { getServerData, setServerData } = useContext(UserContext);

  const onContentChange = (fld) => (value) => {
    let c = { ...myblog };
    c[fld] = value;
    setMyBlog(c);
  };

  const setupEditor = (editor) => {
    editor.on("submit", () => {
      const length = editor.getContent({ format: "text" }).length;
      if (length === 0) {
        editor.notificationManager.open({
          text: "Short description is mandatory!",
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    if (_.get(props, "id", false)) {
      getServerData("trainer/my-blogs?where[id]=" + props.id)
        .then(setMyBlog)
        .then(() => setMode("Update"))
        .catch((err) => console.log(err));
    }
  }, []);
  useEffect(window.scrollEffect, []);

  useEffect(() => {
    window.setTimeout(() => setResponse({ message: "" }), 5000);
  }, [response]);

  const onSave = (e) => {
    const frm = e.currentTarget;
    e.preventDefault();
    frm.classList.add("was-validated");
    if (frm.checkValidity() === false) {
      return false;
    }
    let frmdata = new FormData(frm);
    frmdata.append("short_description", _.get(myblog, "short_description", ""));
    frmdata.append("description", _.get(myblog, "description", ""));
    setSaving(true);
    setServerData("trainer/my-blogs", frmdata).then((res) => {
      setSaving(false);
      setResponse(res);
      props.onSave();
      props.onClose();
    });
  };

  const photoUploader = (fld, title) => {
    return (
      <>
        <Form.Label>{title}</Form.Label>
        <Form.Control type="file" size="lg" name={fld + "_image"} accept=".jpeg,.png,.jpg,JPEG;" />
        <div className="text-center">
          {!_.isEmpty(_.get(myblog, fld + "_image", "")) && (
            <img src={`${process.env.REACT_APP_API_URL}/uploads/${fld}/${myblog[fld + "_image"]}`} className="thumbnail mt-3" alt="blog" />
          )}
        </div>
      </>
    );
  };

  const renderForm = () => (
    <Form onSubmit={onSave} noValidate className="form contact alertdesign needs-validation">
      <Form.Control type="hidden" name="id" defaultValue={_.get(myblog, "id", "")} />
      <Form.Control type="hidden" name="old_blog_image" defaultValue={_.get(myblog, "blog_image", "")} />
      <Form.Control type="hidden" name="old_banner_image" defaultValue={_.get(myblog, "banner_image", "")} />

      <Row>
        <Col md={12} className="mt-3">
          <Form.Label>Blog Title: </Form.Label>
          <Form.Control type="text" name="name" placeholder="Enter blog Title*" defaultValue={_.get(myblog, "name", "")} required />
          <div className="invalid-feedback">Blog Title is required!</div>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mt-4">
          <Form.Label>Blog URL / Slug: </Form.Label>
          <Form.Control type="text" name="slug" placeholder="Enter blog slug/url" defaultValue={_.get(myblog, "slug", "")} />
        </Col>
        <Col md={6} className="mt-3">
          {photoUploader("banner", "Upload blog Banner image")}
        </Col>
      </Row>

      <Row>
        <Col md={3} className="mt-3">
          {photoUploader("blog", "Upload blog thumbnail image")}
        </Col>
        <Col md={9} className="mt-3">
          <Form.Label>Short Description: </Form.Label>

          <TextEditor
            onInit={(evt, editor) => setupEditor(editor)}
            value={_.get(myblog, "short_description", "")}
            onChange={onContentChange("short_description")}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12} className="mt-3">
          <Form.Label>Description: </Form.Label>
          <TextEditor value={_.get(myblog, "description", "")} onChange={onContentChange("description")} />
        </Col>
      </Row>

      <Row>
        <Col md={12} className="mt-3 text-right">
          {saving && (
            <>
              Saving.. <Spinner animation="border" />
            </>
          )}
          {!saving && response.message === "" && (
            <>
              <Button type="submit" className="profile-save">
                Save
              </Button>
              <span className="clickable btn btn-secondary ml-2" onClick={props.onClose}>
                Go back to listing
              </span>
            </>
          )}
          {!saving && response.message !== "" && (
            <Alert variant={response.success ? "info" : "danger"} className="p-3 mt-2 text-center">
              {response.message}
            </Alert>
          )}
        </Col>
      </Row>
    </Form>
  );

  const renderModal = () => (
    <Modal show={true} size="xl" onHide={_.get(props, "onClose", "")}>
      <Modal.Header closeButton>
        <Modal.Title>{mode} Blog </Modal.Title>
      </Modal.Header>

      <Modal.Body>{renderForm()}</Modal.Body>
    </Modal>
  );

  return (
    <>
      {props.type !== "modal" && renderForm()}
      {props.type === "modal" && renderModal()}
    </>
  );
};

export default BlogForm;
