import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Loader } from "..";

const CalendlyBlock = (props) => {
    const calendlyId = _.get(props, "settings.meeting_ID", "");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="col-sm-12">
            {/* {loading && <Loader />} */}
            <div className="slideInUp wow ">
                <div className="meetingid">
                    <div className="calendly-inline-widget pt-0" data-url={`${calendlyId}?hide_gdpr_banner=1`} style={{ width: '100%', height: '700px' }}>
                    </div>
                </div>
            </div>
        </div >
    );

};

export default CalendlyBlock;
