import React, { useState, useContext, useEffect } from "react";
import { Container, Modal, Button, Form, Row, Col, Spinner, Card, Tabs, Tab } from "react-bootstrap";
import UserContext from "./../contexts/UserContext";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import Utils from "./../Utils";
import { useParams } from "react-router-dom";
import TextEditor from "../components/TextEditor";
import UtilityBlocks from "./../components/UtilityBlocks";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MyBlocks = (props) => {
  let { pgid } = useParams();
  pgid = _.isNumber(parseInt(pgid)) && parseInt(pgid) > 0 ? parseInt(pgid) : false;
  const [pages, setPages] = useState({}); //== page record
  const [mode, setMode] = useState(0); //1: add, 2: edit
  const [copyBlock, setCopyBlock] = useState(false);
  const [blockTab, setBlockTab] = useState("template");
  const [rec, setRec] = useState({});
  const [blocks, setBlocks] = useState({ loading: false, data: [], message: "", error: false });
  const [templateId, setTemplateId] = useState(false);
  const [pbbId, setPBBId] = useState(false);
  const [preview, setPreview] = useState({ blockid: 0, form: null });
  const { getServerData, setServerData, apiHeaders, getUserData } = useContext(UserContext);
  const MySwal = withReactContent(Swal);

  const [contents, setContents] = useState({}); //== Editor's content temporary data on change
  const onContentChange = (fld, value) => {
    let c = { ...contents };
    c[fld] = value;
    setContents(c);
  };

  useEffect(() => {
    if (_.get(rec, "id", 0) > 0) {
      const cont = JSON.parse(rec.content);
      let data = {};
      _.each(cont, (v, k) => {
        if (v.type === "editor" || v.type === "image") {
          _.set(data, k, v.value);
        }
      });
      setContents(data);
      setTemplateId(parseInt(cont.tid));
    }
  }, [rec]);

  useEffect(() => {
    const slug = getUserData().slug;
    getServerData(`trainer/profile/${slug}?mode[]=pagelinks`, true)
      .then((tData) => {
        setPages(tData.pagelinks);
      })
      .catch((msg) => { });
  }, []);

  const getPage = () => {
    return _.get(
      _.filter(pages, (p) => parseInt(p.id) === parseInt(pgid)),
      "0",
      {}
    );
  };

  const fetchMyBlocks = () => {
    let url = "trainer/my-blocks";
    if (pgid > 0) {
      url += `/${pgid}`;
    }
    getServerData(url)
      .then((res) => {
        setBlocks({ ...blocks, loading: false, data: res });
      })
      .catch((err) => {
        setBlocks({ ...blocks, loading: false, data: [], error: true, message: err.message });
      });
  };

  useEffect(fetchMyBlocks, []);

  useEffect(() => {
    if (mode === 0 || mode === 1) {
      if (mode === 1) {
        window.setTimeout(() => window.$("#blocktitleele").focus(), 500);
      }
      setTemplateId(0);
      setContents("");
    }
  }, [mode]);

  const saveForm = (e) => {
    let formData = new FormData(e.currentTarget);
    e.preventDefault();
    if (blockTab === "template") {
      if (parseInt(templateId) <= 0) {
        MySwal.fire({
          title: 'ERROR: Template not selected!',
          icon: 'info',
          confirmButtonColor: '#0f79aa',
          confirmButtonText: 'Ok',
          closeOnClickOutside: false,
          timer: 1500
        })
        return false;
      } else {
        formData.set("tid", templateId);
        _.map(_.omit(_.find(Utils.blocks, { id: templateId }), ["id"]), (v, k) => {
          if (v.type === "editor") {
            formData.set(`content##_${k}`, contents[k]);
          } else {
            formData.set(`content##_${k}`, formData.get(k));
            formData.delete(k);
          }
          formData.set(`type##_${k}`, v.type);
        });
      }
    } else if (blockTab === "utilities") {
      if (parseInt(pbbId) <= 0) {
        MySwal.fire({
          title: 'ERROR: Prebuilt Block not selected!',
          icon: 'info',
          confirmButtonColor: '#0f79aa',
          confirmButtonText: 'Ok',
          closeOnClickOutside: false,
          timer: 1500
        })
        return false;
      } else {
        formData.set("pbbid", pbbId);
        formData.set("settings", JSON.stringify(getUtilitySettings(pbbId, e.currentTarget)));
      }
    }

    formData.set("pgid", pgid);
    formData.set("type", blockTab);

    if (mode === 2) {
      formData.set("id", rec.id);
    }

    setBlocks({ ...blocks, loading: true, message: "Saving block..." });
    setServerData("trainer/my-blocks", formData)
      .then((res) => {
        setMode(0); //== close the dialog
        fetchMyBlocks();
      })
      .catch((err) => {
        setBlocks({ ...blocks, loading: false });
      });
  };

  const photoUploader = (field, pos) => {
    const cont = rec.id > 0 ? JSON.parse(rec.content) : {};
    return (
      <>
        <Form.Label>
          <b>{`Upload Image - ${pos}`}</b>
        </Form.Label>
        <Form.Control type="file" size="lg" name={pos} accept=".jpeg,.png,.PNG,.jpg;" required={_.get(field, "required", false)} />
        <div className="text-center">
          {!_.isEmpty(_.get(cont, pos + ".value", "")) && (
            <>
              <img src={`${process.env.REACT_APP_API_URL}/uploads/blocks/${cont[pos].value}`} className="img-fluid thumbnail mt-3" />
              <input type="hidden" name={`old_${pos}`} value={cont[pos].value} />
            </>
          )}
        </div>
      </>
    );
  };

  const editor = (pos, title) => {
    const cont = rec.id > 0 ? JSON.parse(rec.content) : {};
    return (
      <>
        <Form.Label>
          <b>{title}</b>
        </Form.Label>
        <TextEditor value={_.get(contents, pos, "")} onChange={(val) => onContentChange(pos, val)} />
      </>
    );
  };

  const renderField = (field, pos) => {
    const cont = rec.id > 0 ? JSON.parse(rec.content) : {};
    return (
      <Col className={`col-${field.fullWidth ? "12" : "6"} my-2`} key={pos}>
        {field.type === "image" && photoUploader(field, pos)}
        {field.type === "editor" && editor(pos, `Put your text - ${pos}`)}
        {field.type === "text" && (
          <>
            <Form.Label>
              <b>Enter text - {pos}</b>
            </Form.Label>
            <Form.Control
              name={pos}
              placeholder="Enter your text"
              defaultValue={mode === 2 ? _.get(cont, pos + ".value", "") : ""}
              required={_.get(field, "required", false)}
              minLength={_.get(field, "min", undefined)}
              maxlength={_.get(field, "max", undefined)}
            />
          </>
        )}
        {field.type === "textarea" && (
          <>
            <Form.Label>
              <b>Enter text - {pos}</b>
            </Form.Label>
            <Form.Control
              as="textarea"
              name={pos}
              placeholder="Enter your text"
              defaultValue={mode === 2 ? _.get(cont, pos + ".value", "") : ""}
              required={_.get(field, "required", false)}
              minLength={_.get(field, "min", undefined)}
              maxlength={_.get(field, "max", undefined)}
            />
          </>
        )}
      </Col>
    );
  };

  const getFormFieldValue = (form, s) => {
    if (s.type === "boolean") {
      if (form.elements[`setting[${s.name}]`].checked) {
        return form.elements[`setting[${s.name}]`].value;
      }
    } else if (s.type == "multi") {
      return _.map(
        _.filter(s.choices, (sc) => form.elements[`setting[${s.key}][${sc.text}]`].checked),
        (sc) => sc.value
      ).join(",");
    } else if (form.elements[`setting[${s.name}]`]) {
      return form.elements[`setting[${s.name}]`].value;
    }
  };

  const getUtilitySettings = (blockid, form) => {
    if (!blockid) {
      blockid = preview.blockid;
    }
    if (!form) {
      form = preview.form;
    }

    const settings = UtilityBlocks.getSettingKeys(blockid);
    let attrs = {};
    settings.map((s) => (attrs[_.get(s, "key", s.name)] = getFormFieldValue(form, s)));

    return attrs;
  };

  const showPreview = () => {
    const Comp = UtilityBlocks.getComponent(preview.blockid);
    return (
      <Modal show={true} onHide={() => setPreview({ blockid: 0, form: null })} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Blocks - {UtilityBlocks.getLabel(preview.blockid)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Comp sitesetting={props.sitesetting} settings={getUtilitySettings()} />
        </Modal.Body>
      </Modal>
    );
  };

  const showForm = () => {
    return (
      <>
        <h3 className="py-3">{["", "Add", "Edit"][mode]} Block</h3>
        <Form onSubmit={saveForm} encType="multipart/form-data">
          <Row className="">
            <Col>
              <Form.Label>
                <b>Block Identifier</b>
              </Form.Label>
              <Form.Control
                id="blocktitleele"
                name="name"
                placeholder="Enter block identifier"
                defaultValue={mode === 2 ? _.get(rec, "name", "") : ""}
                required
              />
            </Col>
          </Row>

          <Card className="my-3">
            <Card.Body>
              {mode === 2 && (
                <>
                  <b>Selected Block Template</b>
                  <Button variant="dark" className="p-1 m-1" disabled>
                    <img className="p-0 img-fluid" src={`${process.env.REACT_APP_PUBLIC_URL}/assets/blocks/${templateId}.png`} />
                  </Button>
                </>
              )}
              {mode === 1 && (
                <>
                  <Tabs defaultActiveKey={blockTab} className="mb-3" onSelect={setBlockTab}>
                    <Tab eventKey="template" tabClassName={blockTab === "template" ? "bg-primary text-light" : ""} title="Block Templates">
                      <b>Select Block Template</b>
                      <Row className="mt-2">
                        {Utils.blocks.map((b, idx) => (
                          <Col lg={2} md={4} xs={6} key={idx} className="text-center ml-auto">
                            <Button variant={templateId === b.id ? "dark" : "light"} className="p-1 m-1" onClick={() => setTemplateId(b.id)}>
                              <img className="p-0 img-fluid" src={`${process.env.REACT_APP_PUBLIC_URL}/assets/blocks/${idx + 1}.png`} />
                            </Button>
                          </Col>
                        ))}
                      </Row>
                    </Tab>
                    <Tab eventKey="utilities" tabClassName={blockTab === "utilities" ? "bg-primary text-light" : ""} title="Utility Blocks">
                      <b>Select Pre-built Utilities as blocks</b>
                      <Row>
                        {UtilityBlocks.prebuiltBlocks.map((b, idx) => (
                          <Col key={idx} sm={6}>
                            <Button
                              variant={pbbId === b.id ? "primary" : "light"}
                              className="w-100 mx-0 my-2 p-1 border-primary"
                              onClick={() => setPBBId(b.id)}
                            >
                              <div className=" w-100 p-3 bg-light text-dark">
                                {b.label}
                                {pbbId === b.id && _.get(b, "settings", false) !== false && showPBBSettings(b)}

                                {pbbId === b.id && !_.isUndefined(b.component) && (
                                  <div className="px-3 pt-3 text-right">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={(e) => setPreview({ blockid: b.id, form: e.currentTarget.form })}
                                    >
                                      <span className="fas fa-eye" /> Preview
                                    </button>
                                  </div>
                                )}
                              </div>
                            </Button>
                          </Col>
                        ))}
                      </Row>
                    </Tab>
                  </Tabs>
                </>
              )}
              {blockTab === "template" && templateId > 0 && <Row>{_.map(_.omit(_.find(Utils.blocks, { id: templateId }), ["id"]), renderField)}</Row>}
            </Card.Body>
          </Card>

          <div className="text-right">
            <Button type="submit" variant="primary">
              Save Block
            </Button>
          </div>
        </Form>
      </>
    );
  };

  const showPBBSettings = (block) => {
    return (
      <div className="text-left">
        <hr />
        {block.settings.map((setting) => (
          <>
            {setting.type === "boolean" && (
              <div className="px-3 py-1">
                <input
                  type="checkbox"
                  required={_.get(setting, "required", false)}
                  name={`setting[${setting.name}]`}
                  id={`${block.id}_${setting.name}`}
                  value={true}
                  defaultChecked={_.get(setting, "default", false)}
                />
                <label className="ml-2" htmlFor={`${block.id}_${setting.name}`}>
                  {_.capitalize(setting.name)}
                </label>
              </div>
            )}
            {setting.type === "multi" && (
              <div className="d-flex flex-row mobileflex">
                {setting.choices.map((sc) => (
                  <div className="px-3 py-1">
                    <input
                      type="checkbox"
                      name={`setting[${setting.key}][${sc.text}]`}
                      id={`${block.id}_${sc.text}`}
                      value={sc.value}
                      defaultChecked={_.get(sc, "checked", false)}
                    />
                    <label className="ml-2" htmlFor={`${block.id}_${sc.text}`}>
                      {_.capitalize(sc.text)}
                    </label>
                  </div>
                ))}
              </div>
            )}
            {setting.type === "single" && (
              <Row className="px-3 py-1">
                <Col sm={12} className="mb-2">
                  {_.capitalize(setting.name)}
                </Col>
                {setting.choices.map((c) => (
                  <Col sm={4}>
                    <input
                      type="radio"
                      className="align-top mr-1 emailradio ks"
                      id={`${block.id}_${setting.name}_${c.id}`}
                      name={`setting[${setting.name}]`}
                      value={c.id}
                      defaultChecked={_.get(c, "checked", false)}
                    />
                    <label className="clickable" title="click to select" htmlFor={`${block.id}_${setting.name}_${c.id}`}>
                      {_.get(c, "image", false) !== false && <img className="img-fluid" src={`/assets/block-settings/${c.image}`} />}
                    </label>
                  </Col>
                ))}
              </Row>
            )}
            {setting.type === "input" && (
              <Row className="px-3 py-1">
                <Col sm={3}>{_.capitalize(setting.name)}</Col>
                <Col>
                  <Form.Control
                    type={setting.as}
                    name={`setting[${setting.name}]`}
                    placeholder={_.get(setting, "placeholder", "")}
                    defaultValue={_.get(setting, "default", "")}
                  />
                </Col>
              </Row>
            )}
          </>
        ))}
      </div>
    );
  };

  const copyBlockForm = () => {
    return (
      <Modal show={true} onHide={() => setCopyBlock(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Copy Block</Modal.Title>
        </Modal.Header>
        <Form onSubmit={copyBlockSubmit}>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Label>Block Name</Form.Label>
                <Form.Control name="name" placeholder="Enter block name" defaultValue={rec.name} required />
              </Col>

              <Col className="mt-3">
                <Form.Label>Select Page to copy this block</Form.Label>
                <Form.Select className="form-control" name="page" required>
                  {_.map(pages, (page, k) => (
                    <option key={k} value={page.id}>
                      {page.title}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="info">
              Save
            </Button>
            <Button variant="secondary" onClick={() => setCopyBlock(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  const copyBlockSubmit = (e) => {
    const frm = e.currentTarget;
    e.preventDefault();
    setServerData("trainer/my-blocks-copy", `pid=${frm.page.value}&bid=${rec.id}&name=${frm.name.value}`, "post")
      .then((res) => {
        setCopyBlock(false);
        if (res.success) {
          if (parseInt(pgid) === parseInt(frm.page.value)) {
            fetchMyBlocks();
          }
        } else {
          setBlocks({ ...blocks, loading: false });
        }
      })
      .catch((e) => setBlocks({ ...blocks, loading: false }));
  };

  const toggleData = (bid, checked) => {
    if (window.confirm(`You are going to ${checked ? "ENABLE" : "DISABLE"} this block\n\n Continue?`)) {
      setBlocks({ ...blocks, loading: true, message: `${checked ? "Enabling" : "Disabling"} the block...` });
      setServerData("trainer/my-blocks", `id=${bid}&is_active=${checked ? 1 : 0}`)
        .then((res) => {
          if (res.success) {
            let data = blocks.data.map((p, idx) => ({
              ...p,
              is_active: p.id === bid ? checked : p.is_active,
            }));
            setBlocks({ ...blocks, loading: false, data: data });
          } else {
            setBlocks({ ...blocks, loading: false });
          }
        })
        .catch((e) => setBlocks({ ...blocks, loading: false }));
    }
  };

  const handleDelete = (block) => {
    MySwal.fire({
      title: `You are going to delete the '${block.name}' block..`,
      text: "Do you want proceed with deletion?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0f79aa',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success',
          axios.delete(Utils.apiUrl(`trainer/my-blocks/${block.id}`), apiHeaders()).then((res) => {
            fetchMyBlocks();
          })
        )
      }
    })
  };

  const switchWeight = (p1, p2) => {
    setServerData("trainer/my-blocks-sortify", `p1id=${p1.id}&p1w=${p2.weight}&p2id=${p2.id}&p2w=${p1.weight}`)
      .then((res) => {
        if (res.success) {
          fetchMyBlocks();
        } else {
          setBlocks({ ...blocks, loading: false });
        }
      })
      .catch((e) => setBlocks({ ...blocks, loading: false }));
  };

  const sortActions = (p, idx) => {
    return (
      <>
        {idx < blocks.data.length - 1 && (
          <i
            className="clickable fas fa-2x fa-arrow-alt-circle-down mx-1 text-info"
            title="display down"
            onClick={() => switchWeight(p, blocks.data[idx + 1])}
          />
        )}
        {idx === blocks.data.length - 1 && <i className="fas fa-2x fa-arrow-alt-circle-down mx-1" style={{ color: "#ddd" }} />}
        {idx > 0 && (
          <i
            className="clickable fas fa-2x fa-arrow-alt-circle-up mx-1 text-info"
            title="display up"
            onClick={() => switchWeight(p, blocks.data[idx - 1])}
          />
        )}
        {idx === 0 && <i className="fas fa-2x fa-arrow-alt-circle-up mx-1" style={{ color: "#ddd" }} />}
      </>
    );
  };

  const renderActionButtons = (p) => {
    let cont = JSON.parse(p.content);
    return (
      <div className="d-flex flex-row-reverse">
        <button
          className="btn btn-outline-danger p-1 px-2 mx-1"
          onClick={() => {
            handleDelete(p);
          }}
          style={{ borderRadius: "20px" }}
          title="Delete Block"
        >
          <i className="clickable fas fa-trash" />
        </button>
        {cont.type === "template" && (
          <button
            className="btn btn-outline-dark p-1 px-2 mx-1"
            onClick={() => {
              setMode(2);
              setRec(p);
            }}
            style={{ borderRadius: "20px" }}
            title="Edit Block"
          >
            <i className="clickable fas fa-pen" />
          </button>
        )}
        {cont.type !== "template" && (
          <button
            className="btn btn-outline-secondary p-1 px-2 mx-1"
            style={{ borderRadius: "20px" }}
            disabled={true}
            title="No edits for Utility blocks"
          >
            <i className="fas fa-pen text-secondary" />
          </button>
        )}

        <button
          className="btn btn-outline-secondary p-1 px-2 mx-1"
          style={{ borderRadius: "20px" }}
          title="copy this block"
          onClick={() => {
            setRec(p);
            setCopyBlock(true);
          }}
        >
          <i className="fas fa-copy" />
        </button>

        <span
          className={`clickable fas fa-2x mx-1 ${p.is_active ? "fa-check-circle text-success" : "fa-times-circle text-secondary"}`}
          title={`${p.is_active ? "Disable" : "Enable"} this block`}
          onClick={() => toggleData(p.id, !p.is_active)}
        />
      </div>
    );
  };

  return (
    <Container fluid className="h-100 p-0">
      <div className="profile-wrapper">
        <div className="container">
          {mode === 0 && (
            <a href="/my-pages" className="btn btn-sm btn-primary">
              <i className="fa fa-arrow-left"></i> Back to My Pages
            </a>
          )}
          {(mode === 1 || mode === 2) && (
            <button className="btn btn-sm btn-primary" onClick={() => setMode(0)}>
              <i className="fa fa-arrow-left"></i> Back to My Blocks
            </button>
          )}

          <div className="card-header ui-sortable-handle mt-3">
            <h3 className="card-title">
              {pgid > 0 && <>{_.get(getPage(), "title", "loading..")} :: </>}
              My Blocks{" "}
            </h3>
            <span className="btn float-right">
              <button className="btn btn-primary-outline bg-primary text-light btn-sm mt-1" onClick={() => setMode(1)}>
                Add Block <i className="fas fa-plus"></i>
              </button>
            </span>
          </div>
          {blocks.loading && (
            <div className="bg-light p-5 text-center">
              {blocks.message} <Spinner animation="border" />
            </div>
          )}
          {!blocks.loading && (
            <>
              {blocks.data.length === 0 && (
                <div className="bg-light p-5 text-center">
                  <div className="p-5">
                    Yet to add blocks for your page <b>{_.get(getPage(), "title", "loading..")}</b>! Start adding new page
                    <button className="btn btn-sm btn-success ml-2" onClick={() => setMode(1)}>
                      <i className="fas fa-plus" />
                    </button>
                  </div>
                </div>
              )}
              {mode === 0 && blocks.data.length > 0 && (
                <div className="">
                  <table className="table table-striped borderless">
                    <thead>
                      <tr>
                        <th width="50">Sl.</th>
                        <th>Block</th>
                        <th width="120">Sequence</th>
                        {!_.isNumber(pgid) && <th>Page</th>}
                        <th width="170" className="text-center">
                          Updated On
                        </th>
                        <th width="100" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {blocks.data.map((p, idx) => (
                        <tr key={p.id}>
                          <td>{idx + 1}</td>
                          <td>
                            <b>{p.name}</b>
                          </td>
                          <td>{sortActions(p, idx)}</td>

                          {!_.isNumber(pgid) && <td>{p.page}</td>}
                          <td>{moment(_.isNull(p.updated_at) ? p.created_at : p.updated_at).format("DD-MMM-YY HH:mm a")}</td>
                          <td>{renderActionButtons(p)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}

          {(mode === 1 || mode === 2) && showForm()}
          {copyBlock && copyBlockForm()}
        </div>
      </div>

      {preview.blockid > 0 && showPreview()}
    </Container>
  );
};

export default MyBlocks;
