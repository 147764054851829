import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const CookiePolicy = (props) => {
  useEffect(window.scrollEffect, []);

  return (
    <>
      <Container className="h-100 p-0">
        <div className="help-wrapper">
          <div className="container">
            {/*<nav>
            <ol className="cd-breadcrumb">
               <li><a href="/">Home</a></li>
               <li className="current"><em>Cookie Policy</em></li>
            </ol>
    </nav>*/}
            <div className="AD-studio">
              <h1 className="headingtext slideInUp wow ">Cookie Policy</h1>
              <p className="slideInUp wow">
                <strong>KS-Tverse</strong> is committed to protecting and respecting your privacy and wants you to know exactly how we handle the
                information we receive via our website and through our online services.
              </p>

              <p className="slideInUp wow">
                This policy sets out the basis of how the personal data you provide us or we collect from you, through our website www.kstverse.com,
                will be processed. Please read the following carefully to understand our views and practices with respect to the information you
                provide us.
              </p>

              <p className="slideInUp wow">
                Our site uses cookies to distinguish you from other visitors to provide you with a better experience and to help us improve user
                experience. By continuing to browse our sites, you are agreeing to our use of cookies as stated below.
              </p>

              <p className="slideInUp wow">
                <strong>KS-Tverse </strong> ("us", "we", or "our") uses cookies on www.kstverse.com. By using the Service, you consent to the use of
                cookies. We use cookies for the following purposes: to enable certain functions of the Service, to provide analytics, to store your
                preferences, to enable advertisements delivery, including behavioural advertising.
              </p>

              <p className="slideInUp wow">
                We use both session and persistent cookies on the Service. There are other different types of cookies that we use to run our Services:
              </p>

              <h4 className="slideInUp wow">Vital cookies</h4>

              <p className="slideInUp wow">
                These cookies may be used to authenticate users and prevent fraudulent use of user accounts. When you use and access the Service, we
                may place a number of cookies files in your web browser. There are some Strictly Necessary Cookies for the website to function and
                cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for
                services, such as setting your privacy preferences, logging in or filling in forms. If you consent to the saving of cookies contained
                in content that you have accessed, those cookies will be stored in a dedicated location on your device. You can set your browser to
                block or set an alert for these cookies, but some parts of the site will not work without allowing them.
              </p>

              <h4 className="slideInUp wow">Enhancement Cookies</h4>

              <p className="slideInUp wow">
                These cookies are used to count visit and traffic sources which help us measure and improve the performance of our site. They help us
                to know which pages are the most and least popular and see how visitors move around the site.
              </p>

              <p className="slideInUp wow">
                All information that is collected through these cookies is aggregated and remains anonymous. If you do not allow these cookies we will
                not know when you visited our site, and will not be able to monitor its performance.
              </p>

              <h4 className="slideInUp wow">Advertising cookies</h4>

              <p className="slideInUp wow">
                These cookies can be used to limit the number of times you see an ad, as well as help, manage the delivery of advertising campaigns.
                Advertising cookies are also used to customize the advertising across the website and make it more relevant to you. Advertising
                cookies are placed by third parties such as advertisers, ad platforms and their agents, and can be permanent or temporary cookies.
                They are related to advertising services provided by third parties on our website.
              </p>

              <h4 className="slideInUp wow">Third-party cookies</h4>

              <p className="slideInUp wow">
                These cookies may be used to report usage statistics of the Service, deliver advertisements on and through the Service.
              </p>

              <p className="slideInUp wow">Accepting or blocking cookies:</p>

              <p className="slideInUp wow">
                You can choose to activate or deactivate some of the above-mentioned cookies at any time. Please note that if you delete cookies or
                refuse to accept them, you might not be able to use all the features we offer, store your preferences, and some of our pages might not
                even display properly. We do not accept any responsibility for any consequences relating to improper functioning of our services where
                it results from our inability to save or consult cookies necessary for the functioning of those services or if you have blocked or
                deleted those cookies.
              </p>

              <p className="slideInUp wow">
                If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web
                browser.
              </p>

              <p className="slideInUp wow">We are responsible for the operation of our sites and our Services.</p>
              <p className="slideInUp wow">Our corporate office details are:</p>
              <p>
                <strong>Knowledge Synonyms,</strong>
              </p>
              <p>
                <strong>Logix Technova, 209-210, Block B,</strong>
              </p>
              <p>
                <strong>Sector 132, Noida,</strong>
              </p>
              <p>
                <strong>Uttar Pradesh, India 201304</strong>
              </p>
              <p>
                <strong>Tel.: +1 970 444 5500 0120 498 4492.</strong>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default CookiePolicy;
