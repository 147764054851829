import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Utils from "../../Utils";
import UserContext from "../../contexts/UserContext";
import _ from "lodash";
import moment from "moment";
import { Loader } from "..";
import StarRatings from "react-star-ratings";

const ProductListBlock = (props) => {
  const settings = _.get(props, "settings", {});

  const { getServerData, getUserData, isLoggedIn, loginToMoodle } = useContext(UserContext);
  const slug = Utils.hasSubdomain() ? Utils.subdomain() : getUserData().slug;
  const [tData, setTData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [design, setDesign] = useState(parseInt(_.get(settings, "design", 1)));
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  const [enrollment, setEnrollment] = useState([]);

  const fetchCourses = () => {
    getServerData(
      `trainer/blocks/${slug}?mode[]=products&product_type=${_.get(settings, "product_type", "")}&limit=${_.get(settings, "limit", 3)}`,
      true
    )
      .then((tData) => {
        setTData(tData);
        setLoading(false);
      })
      .catch((msg) => {
        setTData({ success: false, message: msg });
        setLoading(false);
      });
  };

  useEffect(fetchCourses, []);

  const getProductDuration = (product) => {
    return product.is_bundle
      ? _.sum(_.map(product.bundle_products, (bp) => parseInt(bp.is_course ? bp.duration : 0)))
      : product.is_course
        ? product.duration
        : "";
  };
  const getProductlevel = (product) => {
    return product.is_bundle
      ? _.sum(_.map(product.bundle_products, (bp) => (bp.is_course === 1 ? bp.level : "")))
      : product.is_course
        ? product.level
        : " ";
  };

  const renderCourseBox = (product) => {
    return (
      <div className="p-4 homecourses w-100">
        <div className="">
          {/*<span className="new">}New</span>*/}
          <img className="img-fluid rounded w-100" src={`${process.env.REACT_APP_API_URL}/uploads/products/${product.product_image}`} alt="TVerse" />
        </div>
        <div className="mt-3 coursemain">
          <div className="d-flex justify-content-start pt-2 pb-3">
            <div className="libraryTitle HomeCourseTitle text-center w-100">{product.name}</div>
            {/* {(product.is_bundle === 1 || product.is_course === 1) && <span className="">
              <img src={`${process.env.REACT_APP_API_URL}/uploads/products/${product.is_bundle ? 'bundle' : 'course'}.png`} className="img-fluid float-right" alt="" width={"75px"} height={"100px"} />
            </span>} */}
          </div>
          <div
            className="libraryBody homecoursedescription text-center pt-3 pb-5 "
            dangerouslySetInnerHTML={{ __html: product.short_description }}
          ></div>
        </div>
        <div className="HomeExploreCourse mt-4 mb-3 text-center vertiviewcoursebtn">
          <div className="ViewCourseBtn">
            {loggedIn && (
              <>
                {product.is_enrolled === 1 ? (
                  <>
                    <span
                      className="clickable btn btnBlue"
                      title="Proceed to LMS"
                      onClick={(e) => {
                        e.preventDefault();
                        loginToMoodle(document.forms.moodleLoginForm);
                      }}
                    >
                      Proceed To LMS
                    </span>
                  </>
                ) : (
                  <>
                    <a href={`/products/${product.slug}`} className="btn btnBlue">
                      View Details
                    </a>
                  </>
                )}
              </>
            )}

            {!loggedIn && (
              <a href={`/products/${product.slug}`} className="btn btnBlue">
                View Details
              </a>
            )}
          </div>
        </div>
        <span class="courseribbon">
          {" "}
          {(product.is_bundle === 1 || product.is_course === 1) && (
            <span className="">
              <img
                src={`${process.env.REACT_APP_API_URL}/uploads/products/${product.is_bundle ? "bundle" : "course"}.png`}
                className="img-fluid float-right"
                alt=""
                width={"75px"}
                height={"100px"}
              />
            </span>
          )}
        </span>
      </div>
    );
  };

  const renderDesign2CourseBox = (product) => {
    const resources = product.is_bundle ? _.flattenDeep(_.map(product.bundle_products, (bp) => bp.resources)) : product.resources;
    return (
      <Row className="my-5 libraryInfobox w-100">
        <div className="col-md-3 col-12">
          <div className="">
            {/*<span className="new">New</span>*/}
            <img className="img-fluid rounded w-100" src={`${process.env.REACT_APP_API_URL}/uploads/products/${product.product_image}`} alt="TVerse" />
          </div>
        </div>
        <div className="col-md-7 col-12">
          <div className="">
            <div className="libraryTitle">
              {product.name}
              {(product.is_bundle === 1 || product.is_course === 1) && (
                <span>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/products/${product.is_bundle ? "bundle" : "course"}.png`}
                    className="img-fluid float-right"
                    alt=""
                    width={"75px"}
                    height={"100px"}
                  />
                </span>
              )}
            </div>
            <div className="libraryBody" dangerouslySetInnerHTML={{ __html: product.short_description }}></div>
            {resources.length > 0 && (
              <div className="d-flex">
                <span className="textBold">
                  <b>Media:</b> &nbsp;{" "}
                </span>
                {Utils.mediaTypes.map(
                  (m) =>
                    _.find(resources, {
                      type: m[0],
                    }) && (
                      <span className="pr-2 d-flex align-center">
                        {m[1]} <img src={`/assets/images/${m[2]}`} className="ml-1" alt="Tverse" width="15" />
                      </span>
                    )
                )}
              </div>
            )}
            <div className="libraryAuthorInfo">
              <span>
                {" "}
                <b>Date:</b> {new Date(product.created_at).toLocaleDateString()}{" "}
              </span>
              <br />
              {product.is_course === 1 && (
                <>
                  {" "}
                  <span className="textBold">
                    <b>Language:</b>
                  </span>{" "}
                  {product.language} <b>|</b>{" "}
                </>
              )}
              {product.is_course === 1 && (
                <span>
                  {" "}
                  <b>Level:</b> {getProductlevel(product)} <b>|</b>{" "}
                </span>
              )}
              {(product.is_course === 1 || product.is_bundle === 1) && (
                <span>
                  {" "}
                  <b>Duration:</b> {getProductDuration(product)} Hours
                </span>
              )}
            </div>
            <StarRatings
              rating={product.rating.rating}
              starEmptyColor="#dddddd"
              starRatedColor="#f3ac1b"
              starHoverColor="#bfa700"
              starDimension="20px"
              starSpacing="2px"
            />
          </div>

          {product.is_bundle === 1 && renderBundleProducts(product)}
        </div>
        <div className="col-md-2 col-12 d-flex align-items-center">
          <div className="ViewCourseBtn">
            {loggedIn && (
              <>
                {enrollment.includes(product.id) ? (
                  <>
                    <a
                      className="btn btnBlue"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        loginToMoodle(document.forms.moodleLoginForm);
                      }}
                    >
                      Proceed To LMS{" "}
                    </a>
                  </>
                ) : (
                  <>
                    <a href={`/products/${product.slug}`} className="btn btnBlue">
                      View Details
                    </a>
                  </>
                )}
              </>
            )}

            {!loggedIn && (
              <a href={`/products/${product.slug}`} className="btn btnBlue">
                View Details
              </a>
            )}
          </div>
        </div>
      </Row>
    );
  };

  const renderDesign3CourseBox = (product) => {
    const resources = product.is_bundle ? _.flattenDeep(_.map(product.bundle_products, (bp) => bp.resources)) : product.resources;
    return (
      <Row className="my-5 libraryInfobox w-100">
        <div className="col-md-6 col-12">
          <div className="">
            <img className="img-fluid rounded w-100" src={`${process.env.REACT_APP_API_URL}/uploads/products/${product.product_image}`} alt="TVerse" />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="">
            <div className="libraryTitle">
              <h4>{product.name}</h4>
              {(product.is_bundle === 1 || product.is_course === 1) && (
                <span>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/products/${product.is_bundle ? "bundle" : "course"}.png`}
                    className="img-fluid float-right"
                    alt=""
                    width={"75px"}
                    height={"100px"}
                  />
                </span>
              )}
            </div>
            <div className="libraryBody" dangerouslySetInnerHTML={{ __html: product.short_description }}></div>
            {resources.length > 0 && (
              <div className="d-flex mt-3">
                <span className="textBold">
                  <b>Media:</b> &nbsp;{" "}
                </span>
                {Utils.mediaTypes.map(
                  (m) =>
                    _.find(resources, {
                      type: m[0],
                    }) && (
                      <span className="pr-2 d-flex align-center">
                        {m[1]} <img src={`/assets/images/${m[2]}`} className="ml-1" alt="Tverse" width="15" />
                      </span>
                    )
                )}
              </div>
            )}
            <div className="libraryAuthorInfo mt-0">
              <span>
                {" "}
                <b>Date:</b> {new Date(product.created_at).toLocaleDateString()}{" "}
              </span>
              <br />
              {product.is_course === 1 && (
                <>
                  {" "}
                  <span className="textBold">
                    <b>Language:</b>
                  </span>{" "}
                  {product.language} <b>|</b>{" "}
                </>
              )}
              {product.is_course === 1 && (
                <span>
                  {" "}
                  <b>Level:</b> {getProductlevel(product)} <b>|</b>{" "}
                </span>
              )}
              {(product.is_course === 1 || product.is_bundle === 1) && (
                <span>
                  {" "}
                  <b>Duration:</b> {getProductDuration(product)} Hours
                </span>
              )}
            </div>
            <StarRatings
              rating={product.rating.rating}
              starEmptyColor="#dddddd"
              starRatedColor="#f3ac1b"
              starHoverColor="#bfa700"
              starDimension="20px"
              starSpacing="2px"
            />
          </div>

          {product.is_bundle === 1 && renderBundleProducts(product)}

          <div className="ViewCourseBtn mt-3 w-25">
            {loggedIn && (
              <>
                {enrollment.includes(product.id) ? (
                  <>
                    <a
                      className="btn btnBlue"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        loginToMoodle(document.forms.moodleLoginForm);
                      }}
                    >
                      Proceed To LMS{" "}
                    </a>
                  </>
                ) : (
                  <>
                    <a href={`/products/${product.slug}`} className="btn btnBlue">
                      View Details
                    </a>
                  </>
                )}
              </>
            )}

            {!loggedIn && (
              <a href={`/products/${product.slug}`} className="btn btnBlue">
                View Details
              </a>
            )}
          </div>
        </div>
        <div className="col-md-2 col-12 d-flex align-items-center">

        </div>
      </Row>
    );
  };

  const renderBundleProducts = (product) => {
    return (
      <>
        <h6 className="mt-2">This bundle contains below products: </h6>
        <div id={`bundle-${product.id}`}>
          {product.bundle_products.map((bp) => (
            <div class="card bundlecard">
              <div class="card-header bckg-blue">
                <a class="card-link libraryTitle text-white" data-toggle="collapse" href={`#bp-${bp.id}`}>
                  {bp.name}
                </a>
              </div>
              <div id={`bp-${bp.id}`} class="collapse">
                <div class="card-body">
                  <Row>
                    <div className="col-lg-4 col-md-12 text-center mt-2">
                      <img
                        className="img-fluid rounded w-100"
                        src={`${process.env.REACT_APP_API_URL}/uploads/products/${bp.product_image}`}
                        alt={bp.name}
                      />
                    </div>
                    <div className="col-lg-8 col-md-12 mt-2">
                      <div className="libraryBody" dangerouslySetInnerHTML={{ __html: bp.short_description }}></div>
                    </div>
                    <div className="col-lg-8 col-md-12 mt-2">
                      <div className="d-flex">
                        <span className="textBold d-flex">
                          <b>Media:</b> &nbsp;{" "}
                        </span>
                        {Utils.mediaTypes.map(
                          (m) =>
                            _.find(bp.resources, {
                              type: m[0],
                            }) && (
                              <span className="pr-2 d-flex align-center">
                                {m[1]} <img src={`/assets/images/${m[2]}`} className="ml-1" alt="AD" width="15" />
                              </span>
                            )
                        )}
                      </div>
                      <div className="mt-2">
                        <b>Date:</b> {new Date(bp.created_at).toLocaleDateString()}
                        <br />
                        {bp.is_course === 1 && (
                          <>
                            {" "}
                            <span className="textBold">
                              <b>Language:</b>
                            </span>{" "}
                            {bp.language} <b>|</b>{" "}
                          </>
                        )}
                        {bp.is_course === 1 && (
                          <span>
                            <b>Level:</b> {bp.level} <b>|</b> <b>Duration:</b> {bp.duration} Hours{" "}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 mt-2">
                      <a href={`/products/${bp.slug}`} className="btn btnBlue">
                        View Details
                      </a>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          {design === 1 && (
            <Row className="mt-4 mb-4">
              {tData.products.map((product) => (
                <Col key={product.id} xl={4} lg={6} md={6} className="pt-3 d-flex">
                  {renderCourseBox(product)}
                </Col>
              ))}
            </Row>
          )}
          {design === 2 && (
            <Row>
              {tData.products.map((product) => (
                <Col key={product.id} sm={12} className="pt-3 d-flex">
                  {renderDesign2CourseBox(product)}
                </Col>
              ))}
            </Row>
          )}
          {design === 3 && (
            <Row className="mt-4 mb-4">
              {tData.products.map((product) => (
                <Col key={product.id} sm={12} className="pt-3 d-flex">
                  {renderDesign3CourseBox(product)}
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default ProductListBlock;
